.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  z-index: 999;
  display: flex;
}

.iti--allow-dropdown {
  width: 100%;
}

/* Styles for the modal */
.modal {
  display: none;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
  padding: 20px;
  display: block;
  height: 720px;
  overflow-y: scroll;
  width: 500px;
  border-radius: 24px;

  @media only screen and (max-width: 500px) {
    width: 100% !important;
    height: 100vh !important;
    border-radius: unset !important;
    padding: 20px;
  }
}

.heading {
  @media only screen and (max-width: 500px) {
    font-size: 32px !important;
  }
  @media only screen and (max-width: 350px) {
    font-size: 28px !important;
  }
}
.para {
  @media only screen and (max-width: 700px) {
    width: 100% !important;
  }
  @media only screen and (max-width: 380px) {
    font-size: 12px !important;
  }
}
.para-2 {
  @media only screen and (max-width: 380px) {
    font-size: 12px !important;
  }
}
.btn {
  @media only screen and (max-width: 500px) {
    display: flex !important;
    flex-direction: column !important;
    gap: 10px;
    width: 100% !important;
  }
}
.btn-large {
  @media only screen and (max-width: 500px) {
    margin-left: 0px !important;
  }
}

.editProfile {
  &__container {
    input {
      outline: none;
      border: none;
      border-radius: 12px;
      border: 1px solid rgba(102, 102, 102, 0.35);
      padding: 16px;
      font-size: 16px;
      font-weight: 400;
      color: #1e1e2f;
      background-color: transparent;
    }
    .ant-tabs-ink-bar {
      height: 4px !important;
    }

    .ant-tabs-nav-list {
      @media only screen and (max-width: 510px) {
        gap: 10px;
      }
    }

    .formatCaption {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      justify-content: end;
      /* identical to box height */

      color: #344054;
    }

    .ant-tabs-tab-btn {
      width: inherit;
      text-align: center;
      font-family: "Space Grotesk";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      min-width: 56px;
    }
    .ant-upload-wrapper {
      width: auto;
    }

    .ant-tabs-nav {
      margin-left: 12px;
      margin-right: 12px;
    }

    .ant-tabs-top > .ant-tabs-nav::before,
    .ant-tabs-top.ant-tabs-nav {
      color: #999999;
      border: 0.5px solid;
      margin-right: 10px;
    }

    display: flex;
    flex-direction: column;
    padding: 12px 12px;
    width: 100%;

    h2 {
      font-size: 40px;
      font-weight: 700;
      color: var(--Text-Primary);
      @media only screen and (max-width: 700px) {
        font-size: 32px !important;
      }
      @media only screen and (max-width: 500px) {
        font-size: 28px !important;
      }
      @media only screen and (max-width: 400px) {
        font-size: 26px !important;
      }
      @media only screen and (max-width: 350px) {
        font-size: 22px !important;
      }
    }
    p {
      margin-top: 8px;
      font-size: 13px;
      color: var(--Dark-Grey);
      font-weight: 400;
      margin-bottom: 24px;
    }
    &-social {
      display: flex;
      flex-direction: column;
      gap: 24px;
      button {
        outline: none;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 14px;
        padding: 12px;
        width: 100%;
        border-radius: 24px;
        font-size: 14px;
        font-weight: 700;
        color: #f2f4f7;
        &:first-of-type {
          background: #4285f4;
        }
        &:last-of-type {
          background: #0288d1;
        }
      }
    }
    &-seperator {
      display: flex;
      align-items: center;
      gap: 23px;
      font-size: 16px;
      font-weight: 300;
      color: var(--Text-Primary);
      margin-block: 32px 16px;
      span {
        width: 100%;
        flex: 1;
        height: 2px;
        background-color: var(--Dark-Grey);
      }
    }
    form {
      display: flex;
      flex-direction: column;
      gap: 24px;
      .select {
        .ant-select-selector {
          background-color: transparent;
          height: 56px;
          border-radius: 12px;
          outline: none !important;
          border-color: rgba(102, 102, 102, 0.35) !important;

          &:hover {
            border-color: rgba(102, 102, 102, 0.35) !important;
            outline: none !important;
          }
          &:focus {
            outline: none !important;
            border-color: rgba(102, 102, 102, 0.35) !important;
          }
          font-family: "Space Grotesk";
          .ant-select-selection-item {
            padding-inline-start: 6px;
          }
          .ant-select-selection-overflow {
            // padding-inline-start: 6px;
            max-height: 50px;
            overflow-x: hidden;
            padding: 5px 0 10px 0;
          }
        }
      }
      div.resume-box {
        display: flex;
        flex-direction: column;
        p {
          font-size: 16px;
          font-weight: 400;
          color: var(--Text-Primary);
          display: flex;
          align-items: center;
          justify-content: space-between;
          svg {
            cursor: pointer;
          }
        }
      }
      label {
        display: flex;
        flex-direction: column;
        gap: 7px;
        span {
          font-size: 16px;
          font-weight: 400;
          color: var(--Text-Primary);
          display: flex;
          align-items: center;
          justify-content: space-between;
          svg {
            cursor: pointer;
          }
        }
        input {
          outline: none;
          border: none;
          border-radius: 12px;
          border: 1px solid rgba(102, 102, 102, 0.35);
          padding: 16px;
          font-size: 16px;
          font-weight: 400;
          color: #1e1e2f;
          background-color: transparent;
        }
        .input {
          outline: none;
          border: none;
          border-radius: 12px;
          border: 1px solid rgba(102, 102, 102, 0.35);
          padding: 16px;
          font-size: 16px;
          font-weight: 400;
          color: #1e1e2f;
          background-color: transparent;
          cursor: pointer;
        }
      }

      p {
        font-size: 13px;
        font-weight: 400;
        text-align: center;
        color: var(--Text-Primary);
        a {
          color: #5937cc;
          text-decoration: underline;
        }
      }
    }
  }
}

/* Custom styles for the option icons */
.roleType__option {
  display: flex;
  align-items: flex-start; /* Align items to the top */
  padding-top: 50px;

  /* Optional: Add some padding to separate the icon from the text */
}

.roleType__option .ant-select-item-option-content {
  display: flex;
  align-items: flex-start; /* Align the content to the top */
}
// .ant-select .ant-select-arrow .anticon {
//   // margin-bottom: 18px !important;
//   display: flex !important;
//   justify-content: center !important;
//   align-items: center !important;
// }
.custom-select {
  .ant-select-selector {
    background-color: transparent;
    height: 56px;
    border-radius: 12px;
    outline: none !important;
    border-color: rgba(102, 102, 102, 0.35) !important;

    &:hover {
      border-color: rgba(102, 102, 102, 0.35) !important;
      outline: none !important;
    }

    &:focus {
      outline: none !important;
      border-color: rgba(102, 102, 102, 0.35) !important;
    }

    font-family: "Space Grotesk";

    .ant-select-selection-item {
      padding-inline-start: 6px;
    }

    .ant-select-selection-overflow {
      max-height: 50px;
      overflow-x: hidden;
      padding: 5px 0 10px 0;
    }
  }

  .ant-select-arrow .anticon {
    margin-bottom: 18px !important;
  }
}
