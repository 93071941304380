.avatar {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fbad84;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}