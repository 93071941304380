@import "../../index.scss";

.partner-resource-container{
  padding: 0 96px;
  margin-top: 40px;
  @media screen and (max-width: $bp-smallest) {
    padding: 0 20px;
  }
  @media screen and (max-width: $bp-small) {
    padding: 0 20px;
  }

  .resource-nav-desktop{
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: $bp-smallest) {
      display:none;
    }
  }

  .resource-nav-mobile{
    display: none;
    @media screen and (max-width: $bp-smallest) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .logo-div{
    display: flex;
    align-items: center;
  }
  .line {
    border-right: 2px solid rgba(30, 30, 30, 0.5); /* Adjust the color and opacity as needed */
    padding-right: 20px;
    height: 50px;
    @media screen and (max-width: $bp-smallest) {
      border-right: none;
    }
  }

  .nav-links{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    padding-left: 50px;
    @media screen and (max-width: $bp-small) {
      padding-left: 25px;
    }
  }
  .link-text{
    font-size: 14px;
    color: #9AA0A9;
  }

  .navbar__col-2{
    &--input{
      width: 212px;
      height: 42px;
      padding: 5px 7px;
      border: none;
      border-bottom: 1px solid #EDEDED;
      color: #999999;
      font-family: "Space Grotesk", sans-serif;
      @media screen and (max-width: $bp-small) {
        width: 170px;
        padding: 5px 7px;
      }
    }
    &--input:focus{
      outline: none;
    }
    &--input::placeholder {
      color: #999999;
      font-family: "Space Grotesk", sans-serif;
      font-size: 16px;
      line-height: "20px";
      @media screen and (max-width: $bp-small) {
        width: 170px;
        font-size: 14px;
      }
    }
  }

  .resource-content-main{
    margin-top: 80px;
    .content-header{
      img{
        width: 80px;
        height: 80px;
        border-radius: 46px;
      }
      h3{
        margin-top: 10px;
        color: #000000;
        font-size: 24px;
      }
      h6{
        margin-top: 3px;
        color: #344054;
        font-size: 16px;
        font-weight: 300;
      }
    }

    .content-details{
      width: 100%;
      border-top: 2px solid #d0cdcd;
      border-bottom: 2px solid #d0cdcd;
      display: flex;
      margin: 50px 0 150px 0;
      @media screen and (max-width: $bp-smallest) {
        flex-direction: column;
      }
      .content-description{
        padding-right: 40px;
        width: 50%;
        border-right: 2px solid #d0cdcd;
        @media screen and (max-width: $bp-smallest) {
          width: 100%;
          border-right: none;
        }
        hr{
          margin-top: 30px;
          border-top: 2px solid #d0cdcd;
        }
        &__sec{
          padding: 30px 0;
        }
        p{
          margin-top: 10px;
          word-wrap: break-word;
          overflow-wrap: break-word;
          max-width: 612px;
          font-weight: 400;
          font-size: 16px;
          color: #1E1E2F;
        }
      }
      .content-pointers{
        padding-left: 40px;
        width: 50%;
        @media screen and (max-width: $bp-smallest) {
          width: 100%;
          border-top: 2px solid #d0cdcd;
          padding-left: 0;
        }
        hr{
          margin-top: 30px;
          border-top: 2px solid #d0cdcd;
        }
        ol{
          padding: 15px 0 0 20px;
        }
        ol li{
          color: #1E1E2F;
          font-weight: 400;
          padding-top: 3px;
        }
        &__sec{
          padding: 30px 0;
        }
        p{
          margin-top: 10px;
          word-wrap: break-word;
          overflow-wrap: break-word;
          max-width: 612px;
          font-weight: 400;
          font-size: 16px;
          color: #1E1E2F;
        }
      }
    }

  }

}