.onboarding {
  max-width: 1280px;
  margin: 0 auto;
}

.onboarding-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.onboarding-spinner{
    display: flex;
    justify-content: center;
    align-items: center;
}

.onboarding-section {
  display: flex;
  align-items: center;
  gap: 104px;
  padding: 57px 0px 25px 0px;
  justify-content: center;
  flex: 1;
  width: 100%;

  @media only screen and (max-width: 1300px){
    padding: 57px 20px 25px 20px;
  }

  @media only screen and (max-width: 1025px) {
    img{
      width: 612px;
    }
    flex-direction: column;
    padding: 57px 50px 25px 50px;
    gap: 50px;
  }
  @media only screen and (max-width: 600px) {
    img{
      width: 612px;
      height: 420px;
    }
    gap: 20px;
  }
  @media only screen and (max-width: 460px) {
    img{
      height: 350px;
    }
  }

  @media only screen and (max-width: 400px){
    padding: 57px 30px 25px 30px;
  }
  @media only screen and (max-width: 360px){
    padding: 57px 20px 25px 20px;
  }

}
.onboarding-details-container {
  background: var(--very-lite-purple, #faf9ff);
  border-radius: 24px;
  padding: 60px;
  height: 670px;

  @media only screen and (max-width: 1300px){
    margin-right: 20px;
  }
  @media only screen and (max-width: 1150px){
    padding: 40px;
  }
  @media only screen and (max-width: 1025px){
    padding: 40px;
    height: 520px;
  }
  @media only screen and (max-width: 650px){
    padding: 20px;
  }
}
.buttonWithArrow {
  border-radius: 24px;
  background: var(--Text-Primary);
  display: flex;
  height: 48px;
  padding: 0 12px 0 16px;
  align-items: center;
  justify-content: space-between;
  width: 202px;
  line-height: 1;
  color: white;
  font-size: 14.5px;
  font-weight: 500;
  line-height: 140%;
}
.onboarding-description-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.onboarding-description {
  color: var(--text-primary, #1e1e2f);
  font-family: Space Grotesk;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media only screen and (max-width: 1210px){
    font-size: 22px;
  }
  @media only screen and (max-width: 1060px){
    font-size: 20px;
  }
  @media only screen and (max-width: 1025px){
    font-size: 24px;
  }
  @media only screen and (max-width: 850px){
    font-size: 22px;
  }
  @media only screen and (max-width: 730px){
    font-size: 20px;
  }
  @media only screen and (max-width: 590px){
    font-size: 18px;
  }
  @media only screen and (max-width: 500px){
    font-size: 16px;
  }
}
.sector-details-container {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 50px;
  margin-top: 46px;

  @media only screen and (max-width: 450px){
    margin-bottom: 20px;
    margin-top: 30px;
  }
}
.sector-details {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.salary-in-dollars {
  color: var(--dark, #1e1e2f);
  font-family: Space Grotesk;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media only screen and (max-width: 380px){
    font-size: 32px;
  }
  @media only screen and (max-width: 340px){
    font-size: 30px;
  }
}
.designation-salary {
  color: var(--dark, #1e1e2f);
  font-family: Space Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media only screen and (max-width: 380px){
    font-size: 12px;
  }
  @media only screen and (max-width: 340px){
    font-size: 10px;
  }
}

.talent-question-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 58px;
  width: 100%;

  @media only screen and (max-width: 550px){
    padding: 57px 20px 25px 20px;
  }
  @media only screen and (max-width: 500px){
    padding: 25px 20px 25px 20px;
    margin-top: 0px;
  }
}

.talent-question-section {
  display: flex;
  flex-direction: column;
  gap: 56px;
  align-items: flex-end;

  @media only screen and (max-width: 550px){
    align-items: center;
  }
}

.w3-light-grey {
  background-color: #f1f1f1;
  border-radius: 4px;
  height: 8px;
}

.w3-blue {
  background-color: var(--brand-colors-peach, #fbad84);
  color: white;
  text-align: center;
  border-radius: 4px;
  width: 0%;
  height: 8px;
  transition: all 0.3s ease-in-out;
}

.explorer-mainbar-percentage {
  width: 100%;
}

.explorer-progressive-bar-container {
  background-color: var(--color, #999);
  border-radius: 4px;
  height: 10px;
}

.explorer-progressive-bar-percentage {
  background: var(--brand-colors-color, #5937cc);
  color: white;
  text-align: center;
  border-radius: 4px;
  width: 25%;
  height: 10px;
}

.explorer-bar-width {
  width: 215px;
}

.explorer-bar {
  display: flex;
  align-items: center;
  gap: 5px;
}

.explorer-bar-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  margin-bottom: 32px;
}

.score-title {
  color: var(--color, #999);
  font-family: Space Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.explorer-bar-score {
  color: var(--181818, #181818);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.profile-container {
  width: 60px;
  height: 60px;
  border: 1px solid #ccc;
  padding: 4px;
  border-radius: 50%;
  overflow: hidden;
}

.profile-picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-info-container {
  display: flex;
  align-items: flex-start;
  gap: 22px;
}

.profile-picture-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}

.profile-username {
  color: var(--dark, #1e1e2f);
  text-align: center;
  font-family: Space Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.info-container {
  display: inline-flex;
  padding: 16px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 16px 16px 16px 0px;
  background: var(--very-lite-purple, #faf9ff);
  width: 414px;

  @media only screen and (max-width: 550px){
    width: 100%;
  }
}

.info-description {
  color: var(--dark, #1e1e2f);
  font-family: Space Grotesk;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media only screen and (max-width: 550px){
    font-size: 16px;
  }
  @media only screen and (max-width: 450px){
    font-size: 14px;
  }
  @media only screen and (max-width: 350px){
    font-size: 12px;
  }
  @media only screen and (max-width: 330px){
    font-size: 10px;
  }
}

.btn-options {
  width: 472px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  div {
    width: 100%;
  }

  @media only screen and (max-width: 550px){
    width: 100%;
  }

  .selected {
    button {
      background-color: black !important;
      color: white !important;
      border: none !important;
    }
  }
}

.progressive-bar-width {
  width: 449px;

  @media only screen and (max-width: 550px){
    width: 100%;
  }
}

.info-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.info-header {
  color: var(--dark, #1e1e2f);
  font-family: Space Grotesk;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.the-explorer {
  color: var(--text-primary, #1e1e2f);
  font-size: 40px;
  font-weight: 700;
  line-height: 114.5%;
  margin-bottom: 24px;
}

.progressive-bar-width ~ svg {
  margin: 66px 0px 16px 0px;
}

.dashboard-btn {
  margin-top: 56px;

  @media only screen and (max-width: 390px){
    margin-top: 32px;
  }
}

.slider-container {
  position: relative;
  width: 100%;
}

.slider-labels {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.slider-labels span {
  font-size: 14px;
  color: #333;
}

.slider-value {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  color: #333;
}
