.btn-primary {
  padding: 8px 16px;
  border-radius: 28px;
  background: var(--dark, #1e1e2f);

  color: var(--neutral-2, #ededed);
  text-align: center;
  font-family: Space Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  
}
.btn-inverted {
  border-radius: 28px;
  border: 1px solid var(--dark, #1e1e2f);
  padding: 8px 16px;
  color: var(--dark, #1e1e2f);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  background-color: white;
  transition: all 0.2s ease-in;


  &:hover {
    background-color: #1e1e2f;
    color: white;
  }
}
