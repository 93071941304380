.adminSideBar {
  padding-block: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--Very-Lite-Purple);
  border-radius: 0px 24px 0px 24px;
  width: 280px;
  min-height: 100vh;
  overflow: scroll;

 

  // @media screen and (max-width: 700px){
  //   .adminSideBar__title , ul{
  //     display: none;
  //   }
  // }

  &__title {
    font-size: 16px;
    font-weight: 700;
    margin-block: 18px 93px;
  }

  ul {
    width: 100%;
    display: flex;
    list-style: none;
    cursor: pointer;

    flex-direction: column;
    li {
      display: flex;
      padding: 27px 16px 27px 83px;
      align-items: center;
      font-size: 14px;
      font-weight: 700;
      gap: 16px;
      transition: all 0.3s;
      &.active {
        background-color: #d4cbef;
        color: #5937cc;
        svg {
          path {
            stroke: #5937cc;
          }
        }
      }
    }
  }
}
 // for mobile navigation 
.bottom-nav {
  display: none;


  @media screen and (max-width: 700px) {
    display: flex; 
    justify-content: space-around; 
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #faf9ff; 
    color: var(--Text-Primary);
    padding: 10px 0; 
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    z-index: 9999;
    li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      font-weight: 700;
      color: var(--Text-Primary);
      transition: all 0.2s;
      list-style: none;
      &.active {
        background-color: #d4cbef;
        color: #5937cc;
        svg {
          path {
            stroke: #5937cc;
          }
        }
      }

      @media screen and (max-width: 500px) {
        font-size: 9px;
      }
      @media screen and (max-width:430px) {
        font-size: 7px;
      }
      @media screen and (max-width: 370px) {
        font-size: 6px;
      }
   }
}
}