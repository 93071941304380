.search-box-container {
  display: flex;
  align-items: center;
  border: 1px solid #999999;
  border-radius: 24px; /* You can adjust the border-radius to change the roundness */
  padding: 10px;
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #999999;
    opacity: 1; /* Firefox */
    font-weight: 400;
  }
  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.search-box {
  border: none;
  outline: none;
  width: 100%;
  padding: 5px;
  font-size: 16px;
  margin-left: 10px;
  background-color: transparent;
}
