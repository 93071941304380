.plan-features__container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    margin-top: 2rem;
    @media only screen and (max-width: 74.999375em) {
        gap: 8px;
    }
}

.plan-features__header,
.plan-features__row {
    display: contents;
}

.plan-features__header-feature,
.plan-features__row-feature {
    color: #1e1e2f;
    font-family: "Space Grotesk";
    font-size: 18px;
    font-weight: 500;
    line-height: 150%;
    padding: 0 20px;
    display: flex;
    align-items: center;
    border-radius: var(--Corner-Radius-Corner-Radius-big, 24px)
        var(--Corner-Radius-Corner-Radius-big, 24px) 0px 0px;
    background: var(--Very-Lite-Purple, #faf9ff);
}

.plan-features__header-plan,
.plan-features__row-plan {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border-radius: var(--Corner-Radius-Corner-Radius-big, 24px)
        var(--Corner-Radius-Corner-Radius-big, 24px) 0px 0px;
    background: #feedcc;
    min-width: 294px;
    min-height: 172px;
    @media only screen and (max-width: 91.805625em) {
        min-width: 247px;
        max-width: 247px;
    }
    @media only screen and (max-width: 82.01375em) {
        min-width: 217px;
        max-width: 217px;
    }
}

.plan-features__row-plan {
    font-size: 1.5rem; // Ensure the check mark is more visible
}

.plan-features__row {
    border-top: 1px solid #eaeaea;
}

.plan-features__header {
    background-color: #f0f0f0;
}




















