.main__dashboard {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  //   margin-left: 300px;

  &-recent-activities {
    /* Rectangle 4414 */

    // max-width: 1032px;
    margin-top: 40px;
    background: #faf9ff;
    border-radius: 24px;
    padding: 30px 30px;

    height: fit-content;
    max-height: 500px;
  }

  &-recent-activities-container {
    margin-top: 20px;
  }

  &-box {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 35px 28px 66px 64px;
    max-width: 1400px;
    width: 1400px;

    @media only screen and (max-width: 1550px){
      max-width: 1150px;
      min-width: 1150px;
      padding: 35px 64px 66px 64px;
  
    }
    @media only screen and (max-width: 1460px){
      max-width: 1050px;
      min-width: 1050px;
  
    }
    @media only screen and (max-width: 1360px){
      max-width: 950px;
      min-width: 950px;
  
    }
    @media only screen and (max-width: 1260px){
      max-width: 850px;
      min-width: 850px;
  
    }
    @media only screen and (max-width: 1190px){
      max-width: 750px;
      min-width: 750px;
  
    }
    @media only screen and (max-width: 1060px){
      max-width: 650px;
      min-width: 650px;
  
    }
    @media only screen and (max-width: 990px){
      max-width: 600px;
      min-width: 600px;
  
    }
    @media only screen and (max-width: 940px){
      max-width: 550px;
      min-width: 550px;
      
    }
    @media only screen and (max-width: 890px){
      max-width: 500px;
      min-width: 500px;
  
    }
    @media only screen and (max-width: 790px){
      max-width: 450px;
      min-width: 450px;
      padding: 35px 20px 66px 20px;
  
    }
    @media only screen and (max-width: 760px){
      max-width: 400px;
      min-width: 400px;
  
    }
    @media only screen and (max-width: 700px){
      max-width: 100%;
      min-width: 100%;
      padding: 35px 44px 66px 44px;
  
    }
    @media only screen and (max-width: 420px){
      max-width: 100%;
      min-width: 100%;
      padding: 35px 34px 66px 34px;
    }
    @media only screen and (max-width: 400px){
      padding: 35px 20px 66px 20px;
    }


  }
  &-heading {
    display: flex;
    width: 100%;
    height: fit-content;
    transform-origin: top;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 0.5px #999999 solid;
    border-color: #999;

    @media only screen and (max-width: 890px){
      flex-direction: column;
      gap: 30px;
   
     }
    h1 {
      font-size: 40px;
      font-weight: 700;


      @media only screen and (max-width: 1260px){
        font-size: 32px;
     
       }
      @media only screen and (max-width: 890px){
       font-size: 30px;
    
      }
      @media only screen and (max-width: 500px){
        font-size: 24px;
     
       }
       @media only screen and (max-width: 350px){
        font-size: 22px;
     
       }
    }

    button {
      outline: none;
      border: none;
      cursor: pointer;
      padding: 12px 40px;
      border-radius: 55px;
      border: 1px solid var(--Purple);
      background: var(--Purple);
      color: var(--Very-Lite-Purple, #faf9ff);
      text-align: center;
      font-size: 14px;
      font-weight: 700;
      height: min-content;
      transition: all 0.3s;
      &:hover {
        background-color: var(--Text-Primary);
      }

        @media only screen and (max-width: 940px){
        //  font-size: 12px;
         padding: 12px 30px;
        }
        @media only screen and (max-width: 890px){
           font-size: 12px;
           padding: 12px 20px;
        }
        @media only screen and (max-width: 790px){
            font-size: 10px;
        }

    }
    .btn-admin{
      @media only screen and (max-width: 1190px){
       font-size: 12px !important;
       padding: 4px 22px !important;
    
      }
      @media only screen and (max-width: 990px){
        font-size: 10px !important;
        padding: 4px 12px !important;
     
       }
    }
  }
  &-container {
    display: flex;
    flex-direction: column;
    &-title {
      font-size: 24px;
      font-weight: 700;
      color: var(--Text-Primary);
      margin-bottom: 36px;
    }
    &-analytics {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;

      &-purple {
        circle {
          fill: #bdafeb;
        }
      }
      &-lGreen {
        circle {
          fill: #91c7b1;
        }
      }
      &-lPeach {
        circle {
          fill: #fdceb5;
        }
      }

      .searchSection{

        @media only screen and (max-width: 1190px){
          width: 100% !important;
      }

      }
      .filterSection{

        @media only screen and (max-width: 1190px){
          width: 100% !important;
        }

      }
    }
  }
}

.iti--allow-dropdown {
  width: 100%;
}

/* Styles for the modal */

.filterSection {
  .ant-select-single {
    height: auto !important; /* Removes the fixed height and lets content determine the height */
  }
  .select {
    .ant-select-arrow {
      margin-right: 20px;
    }
    .ant-select-selector {
      background-color: transparent;
      height: 52px;
      border-radius: 24px;
      outline: none !important;
      border-color: #999999 !important;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 400;
      padding-left: 20px;
      .ant-select-selection-item {
        span {
          display: none !important;
        }
      }
      &:hover {
        border-color: rgba(102, 102, 102, 0.35) !important;
        outline: none !important;
      }
      &:focus {
        outline: none !important;
        border-color: rgba(102, 102, 102, 0.35) !important;
      }
      font-family: "Space Grotesk";
      .ant-select-selection-item {
        padding-inline-start: 6px;
      }
    }
  }
  input {
    outline: none;
    border: none;
    border-radius: 12px;
    border: 1px solid rgba(102, 102, 102, 0.35);
    padding: 16px;
    font-size: 16px;
    font-weight: 400;
    color: #1e1e2f;
    background-color: transparent;
    cursor: pointer;
    margin-left: 20px;
  }
  .ant-dropdown-menu-item .ant-dropdown-menu-item-only-child {
    padding: 10px 20px;
  }
  .roleType__option {
    display: flex !important;
    flex-direction: column !important;
    gap: 6px !important;
    transition: all 0.1s !important;
    padding: 12px 32px !important;

    &.ant-select-item-option-selected {
      background-color: #5937cc !important;
      color: white !important;
      .ant-select-item-option-content {
        span {
          color: white !important;
        }
      }
    }

    &:hover {
      background-color: #faf9ff !important;
      .ant-select-item-option-content {
        color: var(--Text-Primary) !important;
        span {
          color: #999 !important;
        }
      }
    }

    .ant-select-item-option-content {
      display: flex !important;
      flex-direction: column !important;
      gap: 2px !important;
      font-family: "Space Grotesk" !important;
      font-size: 16px !important;
      font-weight: 600 !important;
      line-height: 150% !important;
      transition: all 0.1s !important;
      span {
        color: #999 !important;
        font-family: "Space Grotesk" !important;
        transition: all 0.1s !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 150% !important;
        white-space: normal !important;
      }
    }
  }
}


.main__dashboard-recent-activities--admin .main__dashboard-recent-activities{
  @media only screen and (max-width: 83.68em) {
    width: 100% !important;
    overflow-x: scroll !important;
  } 
}


.main__dashboard-container-analytics--admin#main__analytics--admin .filterSection{
  @media only screen and (max-width: 700px) {
    display: flex !important;
  } 
 
}
 
////////////////Loader Container
/// 
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
  flex: 1;
  width: 100%;
}

.tail-spin {
  justify-self: center;
  align-self: center;
}
