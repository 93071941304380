.addResource__dashboard {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    gap: 24px;
    &-box {
        display: flex;
        max-width: 1240px;
        min-width: 1240px;
        flex-direction: column;
        min-height: 100vh;
        padding: 35px 64px 66px 64px;

        @media only screen and (max-width: 1530px){
            max-width: 1140px;
            min-width: 1140px;
        
          }
          @media only screen and (max-width: 1430px){
            max-width: 990px;
            min-width: 990px;
        
          }
          @media only screen and (max-width: 1370px){
            max-width: 900px;
            min-width: 900px;
        
          }
          @media only screen and (max-width: 1180px){
            max-width: 800px;
            min-width: 800px;
        
          }
          @media only screen and (max-width: 1090px){
            max-width: 750px;
            min-width: 750px;
        
          }
          @media only screen and (max-width: 1040px){
            max-width: 700px;
            min-width: 700px;
        
          }
          @media only screen and (max-width: 990px){
            max-width: 650px;
            min-width: 650px;
        
          }
          @media only screen and (max-width: 940px){
            max-width: 600px;
            min-width: 600px;
        
          }
          @media only screen and (max-width: 890px){
            max-width: 550px;
            min-width: 550px;
        
          }
          @media only screen and (max-width: 840px){
            max-width: 500px;
            min-width: 500px;
        
          }
          @media only screen and (max-width: 790px){
            max-width: 450px;
            min-width: 450px;
            padding: 35px 44px 66px 44px;
        
          }
          @media only screen and (max-width: 740px){
            max-width: 400px;
            min-width: 400px;
          }
          @media only screen and (max-width: 700px){
            max-width: 100%;
            min-width: 100%;
          }
          @media only screen and (max-width: 500px){
            padding: 35px 34px 66px 34px;
            
          }
    }
    &-heading {
        display: flex;
        width: 100%;
        height: fit-content;
        transform-origin: top;
        justify-content: space-between;
        padding-bottom: 10px;
        border-bottom: 0.5px #999999 solid;
        border-color: #999;
        h1 {
            font-size: 40px;
            font-weight: 700;

            
            @media only screen and (max-width: 790px){
                font-size: 30px;
            }
            @media only screen and (max-width: 840px){
                font-size: 32px;
            }
            @media only screen and (max-width: 590px){
                font-size: 28px;
            }
            @media only screen and (max-width: 540px){
                font-size: 24px;
            }
        }
        button {
            outline: none;
            border: none;
            cursor: pointer;
            padding: 12px 40px;
            border-radius: 55px;
            border: 1px solid var(--Purple);
            background: var(--Purple);
            color: var(--Very-Lite-Purple, #faf9ff);
            text-align: center;
            font-size: 14px;
            font-weight: 700;
            height: min-content;
            transition: all 0.3s;
            &:hover {
                background-color: var(--Text-Primary);
            }
        }
    }
    &-container {
        form {
            display: flex;
            flex-direction: column;
            button {
                margin-top: 100px;
                outline: none;
                border: none;
                border-radius: 24px;
                background: var(--brand-colors-color, #5937cc);
                padding: 16px;
                color: #f2f4f7;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                width: 400px;
                margin-inline: auto;
                transition: all 0.2s ease-in;

                @media only screen and (max-width: 980px){
                    width: 100%;
                 
                   }
                &:hover {
                    background: var(--Text-Primary);
                }
                &:disabled{
                    opacity: 0.6;
                    pointer-events: none;
                }
            }
            .select {
                .ant-select-selector {
                    background-color: transparent;
                    padding: 12px;
                    height: 56px;
                    border-radius: 12px;
                    width: 100%;
                    outline: none !important;
                    border-color: rgba(102, 102, 102, 0.35) !important;

                       @media only screen and (max-width: 980px){
                        width: 100%;
                     
                       }

                    &:hover {
                        border-color: rgba(102, 102, 102, 0.35) !important;
                        outline: none !important;
                    }
                    &:focus {
                        outline: none !important;
                        border-color: rgba(102, 102, 102, 0.35) !important;
                    }
                    font-family: "Space Grotesk";
                    font-size: 16px;
                    .ant-select-selection-item {
                        padding-inline-start: 6px;
                    }
                    .ant-select-selection-overflow {
                        // padding-inline-start: 6px;
                        max-height: 50px;
                        overflow: auto;
                    }
                }
            }
        }
        &-form {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-row-gap: 24px;
            grid-column-gap: 22px;
            margin-top: 22px;

            @media only screen and (max-width: 980px){
                display: flex;
                flex-direction: column;
                gap: 10px ;
             
               }
            label {
                display: flex;
                flex-direction: column;
                color: #1e1e2f;
                font-size: 16px;
                font-weight: 400;
                line-height: normal;
                gap: 10px;

                @media only screen and (max-width: 1090px){
                    font-size: 12px;
                   }
                   @media only screen and (max-width: 980px){
                    font-size: 16px;
                   }
                input,
                textarea {
                    border: none;
                    outline: none;
                    background: transparent;
                    border-radius: 12px;
                    border: 1px solid rgba(102, 102, 102, 0.35);
                    padding: 16px;
                    color: #1e1e2f;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
                textarea {
                    resize: none;
                    height: 81px;
                    overflow: auto;
                }
            }
        }
    }
    &-successfully {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 409px;
        margin-inline: auto;
        margin-top: 64px;
        svg {
            margin-bottom: 54px;
            margin-right: -20px;
        }
        p {
            color: #1e1e2f;
            text-align: center;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 114.5%; /* 45.8px */
        }
        span {
            margin-block: 24px 36px;
            color: #1e1e2f;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 114.5%; /* 18.32px */
        }
        &-button {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 16px;
            button {
                outline: none;
                border: none;
                background: transparent;
                padding: 12px 40px;
                border-radius: 55px;
                background: var(--brand-colors-color, #5937cc);
                color: var(--Lt-grey, #f2f4f7);
                text-align: center;
                font-size: 14px;
                font-weight: 700;

                &:last-child {
                    color: #1e1e2f;
                    border: 1px solid #1e1e2f;
                    background-color: transparent;
                }
            }
        }
    }
}
