.eventForm {

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;

    form {
      display: flex;
      flex-direction: column;
      gap: 24px;

      label {
        display: flex;
        flex-direction: column;
        gap: 7px;

      
        span {
          font-size: 16px;
          font-weight: 400;
          color: var(--Text-Primary);
          display: flex;
          align-items: center;
          justify-content: space-between;
          svg {
            cursor: pointer;
          }

          @media only screen and (max-width: 1090px){
            font-size: 12px;
           }
           @media only screen and (max-width: 980px){
            font-size: 16px;
           }

        }
        }
        }
        input {
          outline: none;
          border: none;
          border-radius: 12px;
          border: 1px solid rgba(102, 102, 102, 0.35);
          padding: 16px;
          font-size: 16px;
          font-weight: 400;
          color: #1e1e2f;
          background-color: transparent;
        }
        .input {
          outline: none;
          border: none;
          border-radius: 12px;
          border: 1px solid rgba(102, 102, 102, 0.35);
          padding: 16px;
          font-size: 16px;
          font-weight: 400;
          color: #1e1e2f;
          background-color: transparent;
          
          @media only screen and (max-width: 1090px){
            font-size: 12px;
           }
        }
      }
    }