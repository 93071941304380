.yourCoaches {
  &-recent-activities {
    /* Rectangle 4414 */

    background: #faf9ff;
    border-radius: 24px;
    padding: 30px 30px;
    height: 527px;
    margin-top: 100px;

    @media only screen and (max-width: 1070px){
      overflow-x: hidden;
    }
    @media only screen and (max-width: 435px){
      margin-top: 30px;
      padding: 30px 12px;
    }
  }
  .chat-logo{
    svg{

      @media only screen and (max-width: 700px){
        min-width: 50px;
        min-height: 50px;
      }
      @media only screen and (max-width: 700px){
        min-width: 30px;
        min-height: 30px;
      }
    }
  }
  &-find-div {
    // margin-top: 40px;
    background: #edeef7;
    border-radius: 24px;
    padding: 8px 27px;
    max-height: 500px;
    font-size: 18px;
    font-weight: 400;
    width: 440px;
    height: 50px;
    display: flex;
    align-items: center;
    margin-left: 10px;

    @media only screen and (max-width: 1070px){
      width: 320px;
    }
    
    @media only screen and (max-width: 870px){
      width: 220px;
    }
    @media only screen and (max-width: 760px){
      width: 250px;
    }
    @media only screen and (max-width: 350px){
      width: 230px;
      font-size: 12px;
    }
  }
  &-chatbox{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 10px;
  }

  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 35px 64px 66px 64px;
  max-width: 1240px;
  min-width: 1240px;

  @media only screen and (max-width: 1522px){
    max-width: 1140px;
    min-width: 1140px;

  }
  @media only screen and (max-width: 1422px){
    max-width: 990px;
    min-width: 990px;

  }
  @media only screen and (max-width: 1270px){
    max-width: 790px;
    min-width: 790px;

  }
  @media only screen and (max-width: 1070px){
    max-width: 590px;
    min-width: 590px;

  }
  @media only screen and (max-width: 870px){
    max-width: 480px;
    min-width: 480px;
  }
  @media only screen and (max-width: 760px){
    max-width: 410px;
    min-width: 410px;
  }
  @media only screen and (max-width: 700px){
    max-width: 100%;
    min-width: 100%;
  }
  @media only screen and (max-width: 450px){
    max-width: 100%;
    min-width: 100%;
    padding: 35px 34px 66px 34px;
  }
  &__sub-heading{
    color: var(--Text-Secondary);
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 10px;
    border-bottom: 0.5px solid #999;
  }
  &__heading {
    color: var(--Text-Primary);
    font-size: 40px;
    font-weight: 700;
    border-bottom: none;


    @media only screen and (max-width: 870px){
      font-size: 32px;
  
    }
    @media only screen and (max-width: 700px){
      font-size: 36px;
    }
    @media only screen and (max-width: 450px){
      font-size: 30px;
  
    }
  }
  &__container {
    margin-top: 54px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(328px, 1fr));
    gap: 24px;
  }
}
