.dashboardNavbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 42px;

  @media only screen and (max-width:700px){
    justify-content: space-between;
  }

  .logo {
    // Hide the logo by default
    display: none;
  }

  // @media screen and (max-width: 700px) {
  //   .logo {
  //     // Display the logo on smaller screens
  //     display: block;
  //   }
  // }

  .notification {
    margin-inline: 24px 19px;
  }
  &__profile {
    display: flex;
    align-items: center;
    gap: 4px;
    position: relative;
    main {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #fbad84;
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      cursor: pointer;
    }
    &-container {
      position: absolute;
      top: 110%;
      right: 0;
      z-index: 5000;
      padding: 24px;
      transform-origin: top;
      border-radius: 16px;
      background: #faf9ff;
      box-shadow: 0px 24px 56px 0px rgba(167, 174, 186, 0.16);
      display: flex;
      flex-direction: column;
      min-width: 295px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

      @media only screen and (max-width: 370px){
        min-width: 100%
     
       }

      div.profile__box-list {
        display: flex;
        list-style: none;
        flex-direction: column;
        gap: 26px;
        padding-top: 26px;
        a {
          display: flex;
          align-items: center;
          justify-content: space-between;
          transition: all 0.2s ease;
          cursor: pointer;
          div {
            display: flex;
            align-items: center;
            gap: 8px;
            color: var(--Text-Primary);
            font-size: 14px;
            font-weight: 400;
            line-height: 150%;
          }
          > svg {
            transform: rotate(-90deg);
          }
          &:hover {
            margin-right: 4px;
          }
        }
      }

      .profile__box {
        display: flex;
        align-items: center;
        gap: 12px;
        padding-bottom: 26px;
        border-bottom: 0.4px solid rgba(153, 153, 153, 0.4);
        &-avatar {
          width: 48px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-color: #fbad84;
          color: #fff;
          font-size: 16px;
          font-weight: 700;
        }
        &-details {
          display: flex;
          flex-direction: column;
          gap: 2px;
          color: var(--Text-Primary);
          font-size: 16px;
          font-weight: 600;
          line-height: 150%;
          a {
            color: #999;
            font-size: 14px;
            font-weight: 400;
            line-height: 150%;
            text-decoration-line: underline;
          }
        }
      }
    }
  }
}
