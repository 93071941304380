button.formButton {
    margin-top: 32px;
    outline: none;
    border: none;
    background-color: transparent;
    border-radius: 24px;
    background: #5937cc;
    padding: 16px;
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    color: var(--Lite-Grey);
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: center;

    @media only screen and (max-width: 740px){
        font-size: 12px;
        padding: 14px;
    }
    @media only screen and (max-width: 390px){
        font-size: 10px;
        padding: 12px;
        margin-top: 0px;
    }
    @media only screen and (max-width: 350px){
        font-size: 10px;
        padding: 12px;
    }
    &:hover {
        background-color: var(--Text-Primary);
    }
    &:disabled {
        opacity: 0.7;
        cursor: default;
        &:hover {
            background-color: #5937cc;
        }
    }
}
