.tiles-grid {
  display: grid;
  flex: 1 1;
  gap: 40px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  min-height: 45px;
  overflow-y: auto;
  position: relative;
  @media only screen and (max-width: 90.1875em) {
    gap: 30px;
  } 
}
.talentRecommended {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;

  &__box {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 35px 64px 66px 64px;
    max-width: 1240px;
    min-width: 1240px;
    @media only screen and (max-width: 95.5em) {
      max-width: 1050px;
      min-width: 1050px;
    } 
    @media only screen and (max-width: 90.2em) {
      max-width: 1050px;
      min-width: 1050px;
    } 
    @media only screen and (max-width: 86.8em) {
      max-width: 1000px;
      min-width: 1000px;
    } 
    @media only screen and (max-width: 80.5625em) {
      max-width: 900px;
      min-width: 900px;
    } 
    @media only screen and (max-width: 74.32em) {
      max-width: 800px;
      min-width: 800px;
    } 
    @media only screen and (max-width: 68.062em) {
      max-width: 650px;
      min-width: 650px;
    } 
    @media only screen and (max-width: 58.6875em) {
      max-width: 550px;
      min-width: 550px;
    }
    @media only screen and (max-width: 52.4375em) {
      max-width: 450px;
      min-width: 450px;
    }  
    @media only screen and (max-width:  46.25em) {
      max-width: 400px;
      min-width: 400px;
    }
    @media only screen and (max-width:  43.6875em) {
      max-width: unset;
      min-width: 100%;
      padding: 35px 34px 66px 34px;
    }
    @media only screen and (max-width:  25.5625em) {
      max-width: 350px;
      min-width: 350px;
      padding: 0;
    }
    @media only screen and (max-width:  23.75em) {
      max-width: 300px;
      min-width: 300px;
    }
    // @media only screen and (max-width: 1440px) {
    //   max-width: 1150px;
    //   min-width: 1150px;
    // } 
    // @media only screen and (max-width: 1025px) {
    //   min-width: 730px;
    //   max-width: 730px;
    //   padding: 35px 34px 66px 34px;
    // }
    // @media only screen and (max-width: 768px) {
    //   min-width: 470px;
    //   max-width: 470px;
    //   padding: 35px 24px 66px 24px;
    // }
  }
  &__heading {
    display: flex;
    width: 100%;
    height: fit-content;
    transform-origin: top;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 0.5px #999999 solid;
    border-color: #999;
    margin-bottom: 22px;
    h1 {
      font-size: 40px;
      font-weight: 700;
      @media only screen and (max-width: 1024px) {
        font-size: 28px;
      }
      @media only screen and (max-width: 31.25em) {
        font-size: 22px;
      }
    }
  }
  &__recommendations-container {
    display: flex;
    width: 100%;
    margin-top: 40px;
    flex-direction: column;
    gap: 24px;
    &-title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      div {
        color: var(--Text-Primary);
        font-size: 32px;
        font-weight: 700;
        line-height: 100.9%;
        display: flex;
        align-items: center;
        gap: 8px;
        @media only screen and (max-width: 58.6875em) {
          font-size: 20px;
          gap: 6px;
        }
        @media only screen and (max-width: 768px) {
          font-size: 18px;
        }
        svg {
          @media only screen and (max-width: 58.6875em) {
            display: none;
          }
          @media only screen and (max-width: 768px) {
           display: none;
          }
          margin-bottom: -4px;
        }
      }
    }
  }
}
