.viewJobApplication__jobsPage {

    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    gap: 24px;
    &-box {
      display: flex;
      flex-direction: column;
      min-height: 100vh;
      padding: 35px 64px 66px 64px;
      max-width: 1240px;
      min-width: 1240px;
      @media only screen and (max-width: 95.5em) {
        max-width: 1150px;
        min-width: 1150px;
      } 
      @media only screen and (max-width: 90.2em) {
        max-width: 1050px;
        min-width: 1050px;
      } 
      @media only screen and (max-width: 86.8em) {
        padding: 35px 64px 66px 34px;
      } 
      @media only screen and (max-width: 83.68em) {
        max-width: 900px;
        min-width: 900px;
      } 
      @media only screen and (max-width: 74.32em) {
        max-width: 800px;
        min-width: 800px;
      } 
      @media only screen and (max-width: 68.062em) {
        max-width: 650px;
        min-width: 650px;
        padding: 35px 34px 66px 34px;
      } 
      @media only screen and (max-width: 58.6875em) {
        max-width: 550px;
        min-width: 550px;
      }
      @media only screen and (max-width: 52.4375em) {
        max-width: 450px;
        min-width: 450px;
      }  
      @media only screen and (max-width:  46.25em) {
        max-width: 400px;
        min-width: 400px;
      }
      @media only screen and (max-width:  43.6875em) {
        max-width: unset;
        min-width: 100%;
      }
      @media only screen and (max-width:  25.5625em) {
        max-width: 350px;
        min-width: 350px;
        margin-top: 20px;
        padding: 0;
      }
      @media only screen and (max-width:  23.75em) {
        max-width: 300px;
        min-width: 300px;
      }
    }
    &-heading {
      display: flex;
      width: 100%;
      height: fit-content;
      transform-origin: top;
      justify-content: space-between;
      padding-bottom: 10px;
      border-bottom: 0.5px #999999 solid;
      border-color: #999;
      h1 {
        font-size: 40px;
        font-weight: 700;
        @media only screen and (max-width: 52.4375em) {
          font-size: 28px;
        }  
        @media only screen and (max-width: 23.75em) {
          font-size: 24px;
        } 
      }
      button {
        outline: none;
        border: none;
        cursor: pointer;
        padding: 12px 40px;
        border-radius: 55px;
        border: 1px solid var(--Purple);
        background: var(--Purple);
        color: var(--Very-Lite-Purple, #faf9ff);
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        height: min-content;
        transition: all 0.3s;
        @media only screen and (max-width: 52.4375em) {
          padding: 10px 20px;
          border-radius: 48px;
          font-size: 12px;
        } 
        @media only screen and (max-width: 23.75em) {
          padding: 10px 16px;
          border-radius: 44px;
          font-size: 10px;
        } 
        &:hover {
          background-color: var(--Text-Primary);
        }
      }
    }
    &-allApplicants{
        display: flex;
        justify-content: start;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
      }
    &-container {
      display: flex;
      flex-direction: column;
      &-title {
        font-size: 24px;
        font-weight: 700;
        color: var(--Text-Primary);
        margin-bottom: 36px;
      }
      &-analytics {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 24px;
      }
      &-score {
        display: flex;
        gap: 26px;
        margin-top: 40px;
        .score {
          display: flex;
          flex-direction: column;
          padding: 32px 26px 30px 38px;
          gap: 8px;
          border-radius: 24px;
          background: #faf9ff;
          flex: 1;
          div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: var(--Text-Secondry);
            font-size: 12px;
            font-weight: 600;
            span {
              color: var(--Purple);
              text-align: right;
              font-size: 12px;
              font-weight: 500;
              line-height: 150%; /* 18px */
              letter-spacing: -0.24px;
            }
          }
          main {
            display: flex;
            border-radius: 8px;
            background: rgba(153, 153, 153, 0.2);
            width: 100%;
            height: 8px;
            position: relative;
            span {
              position: absolute;
              top: -4px;
              left: 79%;
              display: flex;
              width: 16px;
              height: 16px;
              border: 2px solid #faf9ff;
              border-radius: 100%;
              background-color: #5937cc;
              // transform: translate(-2%, -10%);
            }
            section {
              // flex: 1;
              width: 80%;
              background-color: #5937cc;
              border-radius: 8px 0px 0px 8px;
            }
          }
        }
      }
    }
  }


  
//   .main__dashboard {
//     display: flex;
//     flex: 1;
//     flex-direction: column;
//     width: 100%;
//     gap: 24px;
//     //   margin-left: 300px;

//     &-box {
//       display: flex;
//       flex-direction: column;
//       min-height: 100vh;
//       padding: 35px 28px 66px 64px;
//       max-width: 1400px;
//       width: 1400px;
//       margin-left: 300px;
//     }
//     &-heading {
//       display: flex;
//       width: 100%;
//       height: fit-content;
//       transform-origin: top;
//       justify-content: space-between;
//       padding-bottom: 10px;
//       border-bottom: 0.5px #999999 solid;
//       border-color: #999;
//       h1 {
//         font-size: 40px;
//         font-weight: 700;
//       }
//       button {
//         outline: none;
//         border: none;
//         cursor: pointer;
//         padding: 12px 40px;
//         border-radius: 55px;
//         border: 1px solid var(--Purple);
//         background: var(--Purple);
//         color: var(--Very-Lite-Purple, #faf9ff);
//         text-align: center;
//         font-size: 14px;
//         font-weight: 700;
//         height: min-content;
//         transition: all 0.3s;
//         &:hover {
//           background-color: var(--Text-Primary);
//         }
//       }
//     }
// }
  
  /* Styles for the modal */
  