.pdfViewerModal {
    padding: 60px;
    background-color: white;
    border-radius: 24px;
    position: relative;
    gap: 20px;
    width: 700px;
    // height: 95vh;
    
    @media only screen and (max-width: 500px) {
      width: 100%;
      height: 100vh;
      border-radius: unset;
    } 
    
    &_closeSquareLogo{
      position: absolute;
      top: 28.62px;
      right: 32px;
    }
    &__container {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 999;
      //z-index: 9999;
      background-color: #000000a7;
      width: 100vw;
      min-height: 100vh;
    }
  
    h1 {
      text-align: start;
      font-size: 40px;
      font-weight: 700;
      margin: 10px 0 20px 0;
  
      @media only screen and (max-width: 400px) {
        font-size: 32px;
      }
    }

    button{
      margin-top: 50px;
    }
  }