.coachPanel {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    min-width: unset;
    max-width: unset;
  

    &__mentor-stats{
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;

        h3{
            font-size: 24px;
            font-weight: 700;
        }
        &-statCard{
            margin-top: 30px;
            display: flex;
            justify-content: start;
            align-items: center;
            gap:30px;

            @media only screen and (max-width: 1280px){
              gap: 12px;
            }
            @media only screen and (max-width: 1060px){
              gap: 30px;
              flex-wrap: wrap;
            }
        }
    }

    &__info-container{
        margin-top: 50px ;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        h3{
            margin-top: 30px;
            padding-bottom: 30px;
            font-size: 24px;
            font-weight: 700;
        }
        span{
          font-size: 14px;
          color: #999999;
        }

        &-details{
            display: flex;
            justify-content: start;
            align-items: start;
            gap: 30px; 

            @media only screen and (max-width: 1260px){
              gap: 10px;

            }

            @media only screen and (max-width: 1240px){
              flex-wrap: wrap;
              gap: 30px;
              
            }
        }
    }
   
    
    &__box {
      display: flex;
      flex-direction: column;
      min-height: 100vh;
      padding: 35px 64px 66px 64px;
      max-width: 1240px;
      min-width: 1240px;

      @media only screen and (max-width: 1530px){
        max-width: 1140px;
        min-width: 1140px;
    
      }
      @media only screen and (max-width: 1430px){
        max-width: 990px;
        min-width: 990px;

      }
      @media only screen and (max-width: 1280px){
        max-width: 790px;
        min-width: 790px;
      }
      @media only screen and (max-width: 1080px){
        flex-wrap: wrap;
        max-width: 720px;
        min-width: 720px;
        padding: 35px 24px 66px 24px;
      }
      @media only screen and (max-width: 1010px){
        max-width: 650px;
        min-width: 650px;
        padding: 35px 64px 66px 64px;
      }
      @media only screen and (max-width: 940px){
        max-width: 600px;
        min-width: 600px;
      }
      @media only screen and (max-width: 890px){
        max-width: 550px;
        min-width: 550px;
      }
      @media only screen and (max-width: 840px){
        max-width: 500px;
        min-width: 500px;
      }
      @media only screen and (max-width: 790px){
        max-width: 480px;
        min-width: 480px;
      }
      @media only screen and (max-width: 770px){
        max-width: 400px;
        min-width: 400px;
        padding: 35px 24px 66px 24px;
      }
      @media only screen and (max-width: 700px){
        max-width: 100%;
        min-width: 100%;
        padding: 35px 64px 66px 64px;
      }
      @media only screen and (max-width: 450px){
        max-width: 100%;
        min-width: 100%;
        padding: 35px 34px 66px 34px;
      }
      @media only screen and (max-width: 400px){
        max-width: 100%;
        min-width: 100%;
        padding: 35px 30px 66px 30px;
      }
      @media only screen and (max-width: 370px){
        max-width: 100%;
        min-width: 100%;
        padding: 35px 24px 66px 24px;
      }
    }
    &__heading {
      display: flex;
      width: 100%;
      height: fit-content;
      transform-origin: top;
      justify-content: space-between;
      padding-bottom: 10px;
      border-bottom: 0.5px #999999 solid;
      border-color: #999;
      margin-bottom: 22px;
      h1 {
        font-size: 40px;
        font-weight: 700;

        @media only screen and (max-width: 900px){
          font-size: 32px;
        }
        @media only screen and (max-width: 400px){
          font-size: 30px;
        }
      }
    }
    &__recommendations-container {
      display: flex;
      width: 100%;
      margin-top: 40px;
      flex-direction: column;
      gap: 24px;
  
      &-card{
        display: flex;
        flex-wrap: wrap;
        gap: 50px;
      }
      &-title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        div {
          color: var(--Text-Primary);
          font-size: 32px;
          font-weight: 700;
          line-height: 100.9%;
          display: flex;
          align-items: center;
          gap: 8px;
          svg {
            margin-bottom: -4px;
          }
        }
      }
    }
  }
  