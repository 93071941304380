.profileDetailCard {
  display: flex;
  border-radius: 12px;
  width: 240px;
  padding: 18px 15px;
  justify-content: space-between;
  background-color: #feedcc;
  min-width: 85px;


  span {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .detail {
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    color: var(--Text-Secondary);
    align-items: end;

    @media only screen and (max-width: 1340px) {
      font-size: 10px;
    }
    p {
      font-size: 48px;
      font-weight: 600;
      line-height: 24px;
      color: var(--Text-Primary);
    }
  }
}
// for coach panel screen: 
.coachPanel__box  .profileDetailCard{
  @media only screen and (max-width: 1110px) {
    width: fit-content;
    gap: 25px;
  }
  @media only screen and (max-width: 890px) {
    width: 100%;
  }
}

//for talent dashboard screen:
.dashboard__details-container-profile .profileDetailCard{
  @media only screen and (max-width: 83.68em) {
    width: 70%;
  } 
  @media only screen and (max-width: 74.32em) {
    width: 100%;
  } 
  @media only screen and (max-width: 52.4375em) {
    width: 416px;
  } 
  @media only screen and (max-width:  46.25em) {
    width: 100% ;
  } 
  @media only screen and (max-width:  43.6875em) {
    max-width: unset;
    min-width: 100%;
  }
  @media only screen and (max-width:  23.75em) {
    width: 100%;
  }
}
// for partner dashboard screen: 
.partner__dashboard-container-analytics  .profileDetailCard{
  @media only screen and (max-width: 1240px) {
    width: fit-content;
  }
  @media only screen and (max-width: 1050px) {
    width: 100%;
  }
}