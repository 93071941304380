.requestForLeaveModal {
    padding: 60px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-color: white;
    border-radius: 24px;
    position: relative;
    gap: 20px;
    width: 718px;

    @media only screen and (max-width: 500px) {
        width: 100%;
        height: 100vh;
        border-radius: unset;
        padding: 40px;
    } 

    .select {
        width: 100%;
        .ant-select-selector {
            background-color: transparent;
            height: 56px;
            border-radius: 12px;
            width: 100%;
            outline: none !important;
            border-color: rgba(102, 102, 102, 0.35) !important;
            padding: 11px;

            &:hover {
                border-color: rgba(102, 102, 102, 0.35) !important;
                outline: none !important;
            }
            &:focus {
                outline: none !important;
                border-color: rgba(102, 102, 102, 0.35) !important;
            }
            font-family: "Space Grotesk";
            .ant-select-selection-item {
                padding-inline-start: 6px;
            }
            .ant-select-selection-overflow {
                // padding-inline-start: 6px;
                max-height: 50px;
                overflow: auto;
            }
        }
    }

    &_closeSquareLogo {
        position: absolute;
        top: 28.62px;
        right: 32px;
    }
    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9999;
        background-color: #000000a7;
        width: 100vw;
        min-height: 100vh;
    }

    h1 {
        text-align: center;
        font-size: 30px;
        font-weight: 700;
        @media only screen and (max-width: 400px) {
            font-size: 26px;
        }
    }
    form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        width: 100%;
        label {
            display: flex;
            flex-direction: column;
            align-self: start;
        }
        input {
            outline: none;
            border: 1px solid rgba(102, 102, 102, 0.35);
            color: #999;
            background-color: transparent;
            width: 575px;
            padding: 16px;
            border-radius: 12px;
            font-size: 16px;
            font-weight: 400;
        }
        button {
            margin-top: 12px;
            outline: none;
            border: none;
            background: var(--dark, #1e1e2f);
            padding: 16px 173px;
            color: white;
            border-radius: 24px;
            font-size: 16px;
            cursor: pointer;
            @media only screen and (max-width: 500px) {
               padding: 16px 110px;
            }
        }

        button:disabled {
            outline: none;
            border: none;
            background-color: #e4e4e4;
        }
    }
}

.ant-select-dropdown {
    z-index: 9999 !important;
}
