.yourCoaches {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 35px 64px 66px 64px;
    max-width: 1240px;
    min-width: 1240px;



    &__heading {
        color: var(--Text-Primary);
        font-size: 40px;
        font-weight: 700;
        padding-bottom: 10px;
        border-bottom: 0.5px solid #999;
    }
    &__container {
        margin-top: 54px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(328px, 1fr));
        gap: 24px;
    }
}
