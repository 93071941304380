.footer {
  border-radius: 24px 24px;
  background: var(--Very-Lite-Purple);
  padding: 50px 96px 44px 96px;
  display: flex;
  flex-direction: column;
  margin-top: auto;

  @media only screen and (max-width: 500px){
    background: none;
  }

  &__container {
    max-width: 1248px;
    width: 1248px;
    margin: 0 auto;
  }
  main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 26px;
    border-bottom: 1px solid var(--Text-Primary);
  }
  &__content {
    .logo {
      width: 108px;
      height: 32px;
    }
    .title {
      font-size: 40px;
      font-weight: 600;
      color: var(--Text-Primary);
      width: 75%;
      margin-top: 24px;
      margin-bottom: 16px;
    }
    p {
      color: var(--Grey);
      width: 80%;
    }
    span {
      font-size: 20px;
      font-weight: 400;
      color: var(--Grey);
      margin-top: 60px;
      margin-bottom: 20px;
      display: block;
    }
    .email {
      font-size: 20px;
      font-weight: 500;
      color: var(--Text-Primary);
    }
  }
  &__box {
    display: flex;
    flex-direction: column;
    padding-top: 70px;
    .logo {
      width: 108px;
      height: 32px;
      display: none;
    }
    &-columns {
      display: flex;
      gap: 120px;
    }
    &-column {
      display: flex;
      flex-direction: column;
      gap: 10px;
      span {
        font-size: 20px;
        font-weight: 700;
        color: var(--Text-Primary);
      }
      a {
        font-size: 16px;
        font-weight: 400;
        color: var(--Text-Primary);
      }
    }
    &-socials {
      display: flex;
      align-items: center;
      gap: 16px;
      justify-content: flex-end;
      margin-top: 26px;
    }
    &-top {
      font-size: 20px;
      font-weight: 600;
      color: var(--Text-Primary);
      align-self: flex-end;
      margin-top: auto;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &:hover {
        transform: translateY(-4px);
      }
    }
  }
  &__copyright {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 400;
    color: var(--Text-Primary);

    @media only screen and (max-width: 690px){
      font-size: 12px;
    }

    @media only screen and (max-width: 500px){
      font-size: 10px;
    }

    @media only screen and (max-width: 390px){
      font-size: 8px;
    }
   
    a {
      font-size: 16px;
      font-weight: 400;
      color: inherit;
      @media only screen and (max-width: 690px){
        font-size: 12px;
      }
      @media only screen and (max-width: 500px){
        font-size: 10px;
      }
      @media only screen and (max-width: 390px){
        font-size: 8px;
      }
    }
  }
}

@media only screen and (max-width: 1450px) {
  .footer {
    padding: 50px 64px 44px 64px;
    &__container {
      max-width: unset;
      width: 100%;
      margin: 0 auto;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .footer {
    padding-inline: 30px;
  }
}

@media only screen and (max-width: 870px) {
  .footer {
    &__container main {
      flex-direction: column-reverse;
      gap: 48px;
      .footer {
        &__content {
          .logo {
            display: none;
          }
          .title {
            margin-top: 0;
          }
          span {
            margin-top: 24px;
          }
        }
        &__box {
          padding-top: 0;
          gap: 36px;
          .logo {
            display: flex;
          }
          &-top {
            display: none;
          }
          &-socials {
            justify-content: flex-start;
            margin-top: 0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .footer {
    &__content {
      .title {
        margin-right: 0;
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .footer {
    padding: 0px 19px 11px;
    display: flex;
    flex-direction: column;

    main {
      padding-bottom: 27px;
    }
    &__content {
      .title {
        font-size: 24px;
        font-weight: 600;

        margin-bottom: 16px;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        width: 100%;
      }
      span {
        font-size: 16px;
        font-weight: 400;
        margin-top: 16px;
        margin-bottom: 12px;
      }
      .email {
        font-size: 16px;
        font-weight: 500;
      }
    }
    &__box {
      display: flex;
      flex-direction: column;

      .logo {
        width: 74px;
        height: auto;
      }
      &-columns {
        gap: 70px;
      }
      &-column {
        display: flex;
        flex-direction: column;
        gap: 10px;
        span {
          line-height: 155%;
        }
        a {
          line-height: 155%;
        }
      }
      &-socials {
        margin-top: 16px;
      }
    }
    &__copyright {
      font-size: 10px;
      font-weight: 400;
      color: #999;
      a {
        font-size: 10px;
        font-weight: 400;
        color: inherit;
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .footer__copyright {
    font-size: 8px;
    a {
      font-size: 8px;
    }
  }
}
@media only screen and (max-width: 350px) {
  .footer__copyright {
    font-size: 7px;
    a {
      font-size: 7px;
      gap: 12px;
    }
  }
}

