.postjob__dashboard {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  &-box {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 35px 64px 66px 64px;
    max-width: 1240px;
    min-width: 1240px;

    @media only screen and (max-width: 1522px){
      max-width: 1140px;
      min-width: 1140px;

    }
    @media only screen and (max-width: 1422px){
      max-width: 990px;
      min-width: 990px;

    }
    @media only screen and (max-width: 1370px){
      max-width: 900px;
      min-width: 900px;

    }
    @media only screen and (max-width: 1180px){
      max-width: 800px;
      min-width: 800px;

    }
    @media only screen and (max-width: 1090px){
      max-width: 750px;
      min-width: 750px;

    }
    @media only screen and (max-width: 1030px){
      max-width: 700px;
      min-width: 700px;

    }
    @media only screen and (max-width: 980px){
      max-width: 650px;
      min-width: 650px;

    }
    @media only screen and (max-width: 940px){
      max-width: 600px;
      min-width: 600px;

    }
    @media only screen and (max-width: 890px){
      max-width: 550px;
      min-width: 550px;

    }
    @media only screen and (max-width: 840px){
      max-width: 500px;
      min-width: 500px;

    }
    @media only screen and (max-width: 790px){
      max-width: 450px;
      min-width: 450px;
      padding: 35px 44px 66px 44px;

    }
    @media only screen and (max-width: 740px){
      max-width: 400px;
      min-width: 400px;
    }
    @media only screen and (max-width: 700px){
      max-width: 100%;
      min-width: 100%;
    }
    @media only screen and (max-width: 500px){
      padding: 35px 34px 66px 34px;

    }

  }

  &-heading {
    display: flex;
    width: 100%;
    height: fit-content;
    transform-origin: top;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 0.5px #999999 solid;
    border-color: #999;
    h1 {
      font-size: 40px;
      font-weight: 700;

      @media only screen and (max-width: 790px){
        font-size: 30px;
      }
      @media only screen and (max-width: 840px){
        font-size: 32px;
      }
      @media only screen and (max-width: 590px){
        font-size: 28px;
      }
      @media only screen and (max-width: 540px){
        font-size: 24px;
      }
    }
    button {
      outline: none;
      border: none;
      cursor: pointer;
      padding: 12px 40px;
      border-radius: 55px;
      border: 1px solid var(--Purple);
      background: var(--Purple);
      color: var(--Very-Lite-Purple, #faf9ff);
      text-align: center;
      font-size: 14px;
      font-weight: 700;
      height: min-content;
      transition: all 0.3s;
      &:hover {
        background-color: var(--Text-Primary);
      }
    }
  }
  &-container {
    form {
      display: flex;
      flex-direction: column;
      gap: 24px;
      .select {
        .ant-select-selector {
          background-color: transparent;
          height: 56px;
          width: 328px;
          border-radius: 12px;
          outline: none !important;
          border-color: rgba(102, 102, 102, 0.35) !important;

          border: 1px solid rgba(102, 102, 102, 0.35);
          padding: 16px;
          font-size: 16px;
          font-weight: 400;
          color: #1e1e2f;
          @media only screen and (max-width: 1422px){
            width: 300px;

           }
          @media only screen and (max-width: 1380px){
            width: 270px;

           }
           @media only screen and (max-width: 1190px){
            width: 240px;

           }
           @media only screen and (max-width: 1090px){
            width: 200px;

           }
           @media only screen and (max-width: 980px){
            width: 100%;

           }

          &:hover {
            border-color: rgba(102, 102, 102, 0.35) !important;
            outline: none !important;
          }
          &:focus {
            outline: none !important;
            border-color: rgba(102, 102, 102, 0.35) !important;
          }
          font-family: "Space Grotesk";
          .ant-select-selection-item {
            padding-inline-start: 6px;
          }
          .ant-select-selection-overflow {
            // padding-inline-start: 6px;
            max-height: 30px;
            overflow-y: scroll;
            overflow-x: hidden;
          }
        }
      }

      label {
        display: flex;
        flex-direction: column;
        gap: 7px;
        span {
          font-size: 16px;
          font-weight: 400;
          color: var(--Text-Primary);
          display: flex;
          align-items: center;
          justify-content: space-between;
          svg {
            cursor: pointer;
          }
          @media only screen and (max-width: 1090px){
            font-size: 12px;
           }
           @media only screen and (max-width: 980px){
            font-size: 16px;
           }
        }
        input {
          outline: none;
          border: none;
          border-radius: 12px;
          border: 1px solid rgba(102, 102, 102, 0.35);
          padding: 16px;
          font-size: 16px;
          font-weight: 400;
          color: #1e1e2f;
          background-color: transparent;
          width: 328px;

          @media only screen and (max-width: 1422px){
           width: 300px;

          }
          @media only screen and (max-width: 1370px){
            width: 270px;

           }
           @media only screen and (max-width: 1190px){
            width: 240px;

           }
           @media only screen and (max-width: 1090px){
            width: 200px;

           }
           @media only screen and (max-width: 980px){
            width: 100%;

           }
        }
        .input {
          outline: none;
          border: none;
          border-radius: 12px;
          border: 1px solid rgba(102, 102, 102, 0.35);
          padding: 16px;
          font-size: 16px;
          font-weight: 400;
          color: #1e1e2f;
          background-color: transparent;

        }
      }
    }
    display: flex;
    flex-direction: column;
    &-title {
      font-size: 24px;
      font-weight: 700;
      color: var(--Text-Primary);
      margin-bottom: 36px;
    }
    &-analytics {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
    }
    &-score {
      display: flex;
      gap: 26px;
      margin-top: 40px;
      .score {
        display: flex;
        flex-direction: column;
        padding: 32px 26px 30px 38px;
        gap: 8px;
        border-radius: 24px;
        background: #faf9ff;
        flex: 1;
        div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: var(--Text-Secondry);
          font-size: 12px;
          font-weight: 600;
          span {
            color: var(--Purple);
            text-align: right;
            font-size: 12px;
            font-weight: 500;
            line-height: 150%; /* 18px */
            letter-spacing: -0.24px;
          }
        }
        &.tribaja__score {
          main {
            span {
              background-color: #fbad84;
            }
            section {
              background-color: #fbad84;
            }
          }
        }
        main {
          display: flex;
          border-radius: 8px;
          background: rgba(153, 153, 153, 0.2);
          width: 100%;
          height: 8px;
          position: relative;
          span {
            position: absolute;
            top: -4px;
            left: 79%;
            display: flex;
            width: 16px;
            height: 16px;
            border: 2px solid #faf9ff;
            border-radius: 100%;
            background-color: #5937cc;
            // transform: translate(-2%, -10%);
          }
          section {
            // flex: 1;
            width: 80%;
            background-color: #5937cc;
            border-radius: 8px 0px 0px 8px;
          }
        }
      }
    }
  }
}
