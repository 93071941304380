.employerAnalyticCard {
    display: flex;
    gap: 16px;
    padding: 16px;
    border-radius: 24px;
    background: #faf9ff;
    min-width: 240px;
    @media only screen and (max-width: 58.6875em) {
        margin-bottom: 15px;
        justify-content: space-between;
      } 
      @media only screen and (max-width: 31.25em) {
        border-radius: 12px;
      }
      @media only screen and (max-width: 1260px){
        min-width: 100%;
        justify-content: space-between;
    
      }
    &__container {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        span {
            &.title {
                color: var(--Text-Secondry);
                font-size: 12px;
                font-weight: 400;
                line-height: 24px;
            }
            &.detail {
                color: var(--Text-Secondry);
                font-size: 12px;
                font-weight: 400;
                line-height: 24px;
                display: flex;
                align-items: center;
                gap: 4px;
            }
        }
        div {
            color: var(--Text-Primary);
            font-size: 32px;
            font-weight: 600;
            line-height: 8px;
            margin-block: 16px;
        }
    }
}
