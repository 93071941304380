#pdf-pagination{
    // display: none !important;
}
#pdf-zoom-out{
    // display: none !important;
    margin-left: 300px;
}

#pdf-zoom-in{
    // display: none !important;
}
#pdf-toggle-pagination{
    display: none !important;
}

#pdf-zoom-reset{
    display: none;
    margin-top: 0 !important;
}

#pdf-controls{
    justify-content: space-between;
}

#pdf-renderer{
    // flex-direction: none;
}

#pdf-download{
    margin-top: 49px;
}