.footer {
  border-radius: 24px 24px;
  padding: 50px 96px 44px 96px;
  display: flex;
  margin-top: auto;
  flex-direction: column;
  &__container {
    max-width: 1248px;
    width: 100%;
    margin: 0 auto;
  }
  &__copyright {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--grey, var(--color, #999));
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    a {
      font-weight: 400;
      color: inherit;
    }
  }
  &__links {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}
