.employerBilling {
  max-width: 1248px;
  margin: 0 auto;
  padding: 50px;
  @media only screen and (max-width: 95.5em) {
    max-width: 1150px;
    min-width: 1150px;
  }
  @media only screen and (max-width: 90.2em) {
    max-width: 1050px;
    min-width: 1050px;
  }
  @media only screen and (max-width: 86.8em) {
    padding: 35px 64px 66px 34px;
  }
  @media only screen and (max-width: 82.01375em) {
    padding: 35px 64px 66px 10px;
  }
  @media only screen and (max-width: 83.68em) {
    max-width: 900px;
    min-width: 900px;
  }
  @media only screen and (max-width: 71.875em) {
    padding: 35px 34px 66px 74px;
  }
  @media only screen and (max-width: 74.32em) {
    max-width: 800px;
    min-width: 800px;
  }
  @media only screen and (max-width: 68.062em) {
    max-width: 650px;
    min-width: 650px;
  }
  @media only screen and (max-width: 58.6875em) {
    max-width: 550px;
    min-width: 550px;
  }
  @media only screen and (max-width: 52.4375em) {
    max-width: 450px;
    min-width: 450px;
  }
  @media only screen and (max-width: 46.25em) {
    max-width: 400px;
    min-width: 400px;
  }
  @media only screen and (max-width: 43.6875em) {
    max-width: unset;
    min-width: 100%;
    padding: 35px 34px 66px 34px;
  }
  @media only screen and (max-width: 25.5625em) {
    max-width: 350px;
    min-width: 350px;
    margin-top: 20px;
    padding: 0;
  }
  @media only screen and (max-width: 23.75em) {
    max-width: 300px;
    min-width: 300px;
  }
  h1 {
    margin-top: 52px;
    margin-bottom: 24px;
    color: var(--dark, #1e1e2f);
    font-size: 48px;
    font-weight: 600;
    @media only screen and (max-width: 1025px) {
      font-size: 36px;
    }
    @media only screen and (max-width: 52.375em) {
      font-size: 30px;
    }
    @media only screen and (max-width: 768px) {
      font-size: 24px;
    }
  }
  &__container {
    display: grid !important;
    gap: 24px;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 36px;
    &.seeker {
      grid-template-columns: repeat(3, 1fr);
    }

    @media only screen and (max-width: 1150px) {
      display: flex !important;
      flex-direction: column;
      gap: 24px;
    }

    &.talentBilling {
      //   justify-content: start;
      margin-left: 273px; // Or any other specific margin-left value for talent

      @media only screen and (max-width: "1528px") {
        justify-content: flex-end;
        margin-left: 290px;
      }
      @media only screen and (max-width: "1468px") {
        margin-right: 90px;
        gap: 108px;
      }
      @media only screen and (max-width: "1443px") {
        margin-right: 58px;
        gap: 80px;
      }
      @media only screen and (max-width: "1339px") {
        margin-right: 4px;
        gap: 36px;
      }
      @media only screen and (max-width: "1311px") {
        gap: 60px;
        justify-content: flex-start;
      }
      @media only screen and (max-width: "1189px") {
        gap: 24px;
        justify-content: end;
        // margin-left: 64px;
        margin-right: 24px;
      }
      @media only screen and (max-width: "1150px") {
        max-width: 1150px;
        width: 100%;
        margin: auto;
      }
    }
  }
}

// @media only screen and (min-width: "1189px") {
//   .employerBilling__container {
//     display: flex;
//     flex-direction: column;
//     gap: 24px;
//   }
// }
