.viewAll {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;

  // &__pagination{
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  // border-top: 1px solid  #999999;
  // padding-top: 30px;
  // &-leftArrow{
  //   display: flex;
  //   align-items: center;
  //   gap: 10px;
  //   cursor: pointer;
  //   color: #999999;
  //   font-size: 14px;
  //   font-weight: 400;
  // }
  // &-rightArrow{
  //   display: flex;
  //   align-items: center;
  //   gap: 10px;
  //   cursor: pointer;
  //   color: #999999;
  //   font-size: 14px;
  //   font-weight: 400;
  // }
  // }


  &__box {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 35px 64px 66px 64px;
    max-width: 1240px;
    min-width: 1240px;
    @media only screen and (max-width: 95.5em) {
      max-width: 1150px;
      min-width: 1150px;
    } 
    @media only screen and (max-width: 90.2em) {
      max-width: 1050px;
      min-width: 1050px;
    } 
    @media only screen and (max-width: 86.8em) {
      padding: 35px 64px 66px 34px;
    } 
    @media only screen and (max-width: 83.68em) {
      max-width: 900px;
      min-width: 900px;
    } 
    @media only screen and (max-width: 74.32em) {
      max-width: 800px;
      min-width: 800px;
    } 
    @media only screen and (max-width: 68.062em) {
      max-width: 650px;
      min-width: 650px;
    } 
    @media only screen and (max-width: 58.6875em) {
      max-width: 550px;
      min-width: 550px;
    }
    @media only screen and (max-width: 52.4375em) {
      max-width: 450px;
      min-width: 450px;
    }  
    @media only screen and (max-width:  46.25em) {
      max-width: 400px;
      min-width: 400px;
    }
    @media only screen and (max-width:  43.6875em) {
      max-width: unset;
      min-width: 100%;
    }
    @media only screen and (max-width:  25.5625em) {
      max-width: 350px;
      min-width: 350px;
      margin-top: 20px;
      padding: 0;
    }
    @media only screen and (max-width:  23.75em) {
      max-width: 300px;
      min-width: 300px;
    }
  }
  &__heading {
    display: flex;
    width: 100%;
    height: fit-content;
    transform-origin: top;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 0.5px #999999 solid;
    border-color: #999;
    margin-bottom: 22px;
    h1 {
      font-size: 40px;
      font-weight: 700;
      @media only screen and (max-width:  25em) {
        font-size: 24px;
      }
    }
  }
  &__recommendations-container {
    display: flex;
    width: 100%;
    margin-top: 40px;
    flex-direction: column;
    gap: 24px;

    &-card{
      display: flex;
      flex-wrap: wrap;
      gap: 50px;
    }
    &-title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      div {
        color: var(--Text-Primary);
        font-size: 32px;
        font-weight: 700;
        line-height: 100.9%;
        display: flex;
        align-items: center;
        gap: 8px;
        svg {
          margin-bottom: -4px;
        }
      }
    }
  }
}


