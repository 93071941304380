.partnerRequest {
    width: 100vw;
    min-height: 100vh;
    background: var(--Very-Lite-Purple, #faf9ff);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 11px;

    @media only screen and (max-width: 850px){
        max-width: 100%;
        min-width: 100%;
        padding: 35px 64px 66px 64px;
      }

    svg {
        margin-bottom: 16px;
    }
    h1 {
        color: var(--dark, #1e1e2f);
        text-align: center;
        font-size: 40px;
        font-weight: 700;
        @media only screen and (max-width: 550px){
            font-size: 32px;
          }
          @media only screen and (max-width: 400px){
            font-size: 28px;
          }
          @media only screen and (max-width: 350px){
            font-size: 24px;
          }
          @media only screen and (max-width: 330px){
            font-size: 22px;
          }
    }
    p {
        color: var(--Text-Secondry, #344054);
        text-align: center;
        font-size: 16px;
        font-weight: 400;

        @media only screen and (max-width: 550px){
            font-size: 14px;
          }
          @media only screen and (max-width: 400px){
            font-size: 12px;
          }
          @media only screen and (max-width: 340px){
            font-size: 10px;
          }
    }

    button {
        outline: none;
        border: none;
        cursor: pointer;
        padding: 12px 40px;
        border-radius: 55px;
        border: 1px solid var(--Purple);
        background: var(--Purple);
        color: var(--Very-Lite-Purple, #faf9ff);
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        height: min-content;
        transition: all 0.3s;
        margin-top: 24px;
        &:hover {
            background-color: var(--Text-Primary);
        }

        @media only screen and (max-width: 400px){
            font-size: 12px;
            padding: 12px 30px;
          }
          @media only screen and (max-width: 340px){
            font-size: 10px;
            padding: 12px 25px;
          }
    }
}
