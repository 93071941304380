.calendly {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 35px 64px 66px 64px;
  max-width: 1240px;
  min-width: 1240px;

  @media only screen and (max-width: 1522px){
    max-width: 1140px;
    min-width: 1140px;

  }
  @media only screen and (max-width: 1422px){
    max-width: 990px;
    min-width: 990px;

  }
  @media only screen and (max-width: 1270px){
    max-width: 790px;
    min-width: 790px;
  }
  @media only screen and (max-width: 1070px){
    max-width: 720px;
    min-width: 720px;
  }
  @media only screen and (max-width: 1000px){
    max-width: 650px;
    min-width: 650px;
  }
  @media only screen and (max-width: 930px){
    max-width: 600px;
    min-width: 600px;
  }
  @media only screen and (max-width: 880px){
    max-width: 550px;
    min-width: 550px;
  }
  @media only screen and (max-width: 830px){
    max-width: 500px;
    min-width: 500px;
  }
  @media only screen and (max-width: 780px){
    max-width: 480px;
    min-width: 480px;
  }
  @media only screen and (max-width: 760px){
    max-width: 420px;
    min-width: 420px;
    padding: 35px 24px 66px 24px;
  }
  @media only screen and (max-width: 700px){
    max-width: 100%;
    min-width: 100%;
    padding: 35px 64px 66px 64px;
  }
  @media only screen and (max-width: 450px){
    max-width: 100%;
    min-width: 100%;
    padding: 35px 34px 66px 34px;
  }
  @media only screen and (max-width: 390px){
    max-width: 100%;
    min-width: 100%;
    padding: 35px 34px 66px 14px;
  }
  &__heading {
    color: var(--Text-Primary);
    font-size: 40px;
    font-weight: 700;
    padding-bottom: 10px;
    border-bottom: 0.5px solid #999;

    @media only screen and (max-width: 880px){
      font-size: 32px;
    }
    @media only screen and (max-width: 780px){
      font-size: 30px;
    }
    @media only screen and (max-width: 700px){
      font-size: 36px;
    }
    @media only screen and (max-width: 500px){
      font-size: 30px;
    }
    @media only screen and (max-width: 340px){
      font-size: 28px;
    }
   
  }
  &__container {
    width: 504px;
    height: 269px;
    position: relative;
    top: 100px;
    left: 300px;
    border-radius: 24px;
    background-color: #F2F4F7;

    @media only screen and (max-width: 1270px){
      top: 200px;
      left: 130px;
    }
    @media only screen and (max-width: 1070px){
      top: 200px;
      left: 100px;
      width: 450px;
    }
    @media only screen and (max-width: 1000px){
      top: 200px;
      left: 50px;
      width: 450px;
    }
    @media only screen and (max-width: 930px){
      top: 200px;
      left: 30px;
      width: 430px;
    }
    @media only screen and (max-width: 880px){
      width: 350px;
    }
    @media only screen and (max-width: 780px){
      width: 330px;
    }
    @media only screen and (max-width: 700px){
      width: 504px;
    }
    @media only screen and (max-width: 600px){
      width: 400px;
    }
    @media only screen and (max-width: 500px){
      width: 300px;
    }
    @media only screen and (max-width: 370px){
      top: 200px;
      left: 20px;
      width: 270px;
    }


  }
  &__sub-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 50px;
    .logo{

      @media only screen and (max-width: 700px){
        margin-left: 90px;
      }


    }
    h2{
      margin-bottom: 5px;
      @media only screen and (max-width: 370px){
        font-size: 22px;
      }
    }
    p{
      margin-top: 10px;
      font-size: 12px;
      font-weight: 400;
      color: #344054;

      @media only screen and (max-width: 370px){
        font-size: 10px;
      }
    }
  }
  &__instruction{
    width: 504px;
    height: 403px;
    background-color: #F2F4F7;
    border-radius: 24px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1{
      font-weight: 600;
      font-size: 24px;
    }
    ol{
      font-weight: 600;
    }
    li{
      margin: 9px 0 0 15px;
      font-weight: 400;
      font-size: 12px;
      color: #344054;
    }
  }
  &__button{
    margin-top: 30px;
  }
}