.resource {
  display: flex;
  border-radius: 24px;
  background: #faf9ff;
  width: 330px;
  max-width: 330px;
  flex-direction: column;
  position: relative;
  height: 355px;

  @media only screen and (max-width: 1440px) {
    width: 300px;
    max-width: 300px;
  }
  @media only screen and (max-width: 1340px) {
    width: 280px;
    max-width: 280px;
  }
  @media only screen and (max-width: 1290px) {
    width: 260px;
    max-width: 260px;
  }
  @media only screen and (max-width: 1240px) {
    width: 250px;
    max-width: 250px;
  }
  @media only screen and (max-width: 1190px) {
    width: 330px;
    max-width: 330px;
  }
  @media only screen and (max-width: 1090px) {
    width: 300px;
    max-width: 300px;
  }
  @media only screen and (max-width: 1040px) {
    width: 280px;
    max-width: 280px;
  }
  @media only screen and (max-width: 990px) {
    width: 250px;
    max-width: 250px;
  }
  @media only screen and (max-width: 940px) {
    width: 100%;
    max-width: unset;
  }
  @media only screen and (max-width: 700px) {
    margin: auto;
  }
  @media only screen and (max-width: 350px) {
    margin: 0;
    width: 290px;
    max-width: 290px;
  }

  &__bookmark {
    position: absolute;
    top: 16px;
    right: 24px;
    cursor: pointer;
    background-color: white;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
  }

  // svg.bookmark {
  //   position: absolute;
  //   top: 32px;
  //   right: 32px;
  //   cursor: pointer;
  // }
  svg.bookmarkChecked {
    path {
      stroke: #fdceb5;
      fill: #fdceb5;
    }
  }

  img {
    width: 60px;
  }

  .name {
    font-size: 24px;
    font-weight: 600;
    color: var(--Text-Primary);
    margin-block: 12px 24px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    row-gap: 11px;

    span {
      font-size: 12px;
      font-weight: 400;
      color: var(--Text-Secondary);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 16px;
      background: #ebeef1;
      padding: 2px 8px;
    }
  }

  .description {
    font-size: 12px;
    font-weight: 400;
    color: var(--Text-Secondary);
    margin-block: 16px 8px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  a {
    padding: 12px 40px;
    outline: none;
    border: none;
    background-color: transparent;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    border-radius: 55px;
    border: 0.5px solid var(--Text-Primary);
    background: rgba(30, 30, 47, 0);
    width: fit-content;
    align-self: flex-start;
    text-decoration: none;
  }
}

.resourceSaved {
  background-color: #fdceb5;
}

.content {
  padding: 24px 16px 16px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.image {
  // height: 150px;
  background-size: cover;
  background-position: center;
  border-radius: 24px 24px 0 0;
  padding: 16px 0 0 16px;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.data-button {
  background: #061734;
  border: none;
  padding: 7px 8px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.profile-resource {
  border: 1px solid white;
  border-radius: 100px;
  object-fit: contain;
  aspect-ratio: 1/1;
  width: 78px;
  background-color: white;
}
