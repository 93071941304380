.staffCard {
    border-radius: var(--corner-radius-corner-radius-big, 24px);
    border: 0px solid var(--grey, #999);
    background: var(--very-lite-purple, #faf9ff);
    padding: 20px;
    padding-top: 72px;
    width: 330px;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;

    @media only screen and (max-width: 1440px){
     width: 300px;
  
    }
    @media only screen and (max-width: 1340px){
      width: 280px;
   
     }
     @media only screen and (max-width: 1290px){
      width: 260px;
   
     }
     @media only screen and (max-width: 1240px){
      width: 250px;
   
     }
     @media only screen and (max-width: 1190px){
      width: 330px;
     }
     @media only screen and (max-width: 1090px){
      width: 300px;
     }
     @media only screen and (max-width: 1040px){
      width: 280px;
     }
     @media only screen and (max-width: 990px){
      width: 250px;
     }
     @media only screen and (max-width: 940px){
      width: 100%;
     }

     @media only screen and (max-width: 700px){
      margin: auto;
    }
    @media only screen and (max-width: 350px){
      margin: 0;
      width: 290px;
    }
     
    .role{
      font-size: 16px;
      font-weight: 400;
      margin-block: 8px;
      color: var(--Text-Secondary);
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .name{
      font-size: 24px;
      font-weight: 600;
      line-height: 24px;
      margin-block: 8px 0;
      color: var(--Text-Primary);
      padding-bottom: 12px;
      max-height: 36px;
  
      max-width: 260px;
      text-wrap: nowrap;
      overflow-x: auto;
    }
    .email{
        font-size: 16px;
        font-weight: 400;
        margin-block: 8px;
        color: var(--Text-Secondary);
        display: flex;
        align-items: center;
        gap: 8px;

    }

    .buttons {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 7px;
      margin-top: 30px;
      @media only screen and (max-width: 1440px){
        gap: 4px;
        // width: fit-content;
     
       }
      button{
        border-radius: 55px;
        background: var(--Text-Primary);
        outline: none;
        border: none;
        color: var(--Lite-Grey);
        font-size: 14px;
        font-weight: 700;
        flex: 1;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 42px;
        border: 1px solid var(--Text-Primary);
        &.invert {
          background-color: transparent;
          color: var(--Text-Primary);
        }
        @media only screen and (max-width: 1440px){
          font-size: 12px;
       
         }
        @media only screen and (max-width: 1290px){
          font-size: 10px;
       
         }
         @media only screen and (max-width: 1190px){
          font-size: 14px;
       
         }
         @media only screen and (max-width: 1090px){
          font-size: 12px;
       
         }
         @media only screen and (max-width: 990px){
          font-size: 10px;
       
         }
         @media only screen and (max-width: 940px){
          font-size: 14px;
       
         }
         @media only screen and (max-width: 350px){
          font-size: 12px;
       
         }
        }
    }   
}

  