.paymentSuccess__container{
    width: 100%;
    height: 100vh;
}

.paymentSuccess-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column ;
    margin-top: 250px;
    gap: 15px;

    h1{
        font-size: 42px;
    }
}