.dashboard {
  display: flex;
  flex: 1;
  width: 100%;
  gap: 24px;
  @media only screen and (max-width: 95.5em) {
    gap: 10px;
  }
  @media only screen and (max-width: 90.2em) {
    gap: 0;
  }
  @media only screen and (max-width: 74.37em) {
    flex-direction: column;
  }

  @media only screen and (max-width: 1025px) {
    flex-direction: column;
  }

  &__container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 35px 64px 66px 64px;
    max-width: 1240px;
    min-width: 1240px;
    @media only screen and (max-width: 95.5em) {
      max-width: 1150px;
      min-width: 1150px;
    }
    @media only screen and (max-width: 90.2em) {
      max-width: 1050px;
      min-width: 1050px;
    }
    @media only screen and (max-width: 86.8em) {
      padding: 35px 64px 66px 34px;
    }
    @media only screen and (max-width: 83.68em) {
      max-width: 900px;
      min-width: 900px;
    }
    @media only screen and (max-width: 74.32em) {
      max-width: 800px;
      min-width: 800px;
    }
    @media only screen and (max-width: 68.062em) {
      max-width: 650px;
      min-width: 650px;
    }
    @media only screen and (max-width: 58.6875em) {
      max-width: 550px;
      min-width: 550px;
    }
    @media only screen and (max-width: 52.4375em) {
      max-width: 450px;
      min-width: 450px;
    }
    @media only screen and (max-width: 46.25em) {
      max-width: 400px;
      min-width: 400px;
    }
    @media only screen and (max-width: 43.6875em) {
      max-width: unset;
      min-width: 100%;
    }
    @media only screen and (max-width: 25.5625em) {
      max-width: 350px;
      min-width: 350px;
      margin-top: 20px;
      padding: 0;
    }
    @media only screen and (max-width: 23.75em) {
      max-width: 300px;
      min-width: 300px;
    }
  }
  &__details {
    display: flex;
    // flex: 1;
    flex-direction: column;
    // gap: 32px;
    &-heading {
      display: flex;
      flex-direction: column;
      width: 100%;
      transform-origin: top;
      @media only screen and (max-width: 90.2em) {
        width: 90%;
      }
      @media only screen and (max-width: 83.68em) {
        width: 80%;
      }
      @media only screen and (max-width: 80.87em) {
        width: 70%;
      }
      @media only screen and (max-width: 74.32em) {
        width: 100%;
      }
      h1 {
        font-size: 40px;
        font-weight: 700;
        @media only screen and (max-width: 43.6875em) {
          font-size: 32px;
        }
        @media only screen and (max-width: 23.75em) {
          font-size: 28px;
        }
      }
      p {
        color: #999;
        font-size: 18px;
        font-weight: 400;
        line-height: 100.9%;
        padding-bottom: 12px;
        border-bottom: 0.5px #999999 solid;
        border-color: #999;
      }
    }
    &-container {
      display: flex;
      flex-direction: column;
      gap: 16px;

      &-title {
        font-size: 24px;
        font-weight: 700;
        color: var(--Text-Primary);
      }
      &-profile {
        display: flex;
        flex-direction: column;
        gap: 18px;
      }
      &-box {
        display: flex;
        gap: 24px;
        @media only screen and (max-width: 84.8em) {
          gap: 10px;
        }
        @media only screen and (max-width: 83.68em) {
          flex-direction: column;
        }
        @media only screen and (max-width: 768px) {
          flex-direction: column;
        }
        section {
          border-radius: 24px;
          background: #faf9ff;
          padding: 48px 28px 22px 28px;
          display: flex;
          flex-direction: column;
          align-items: center;
          min-width: 416px;
          justify-content: center;
          //   height: fit-content;
          @media only screen and (max-width: 83.68em) {
            width: 70%;
          }
          @media only screen and (max-width: 74.32em) {
            width: 100%;
          }
          @media only screen and (max-width: 46.25em) {
            min-width: 356px;
            max-width: 356px;
          }
          @media only screen and (max-width: 43.6875em) {
            max-width: unset;
            min-width: 100%;
          }
          @media only screen and (max-width: 25.5625em) {
            min-width: 100%;
          }
        }

        &-profile {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 83px;
          height: 83px;
          background-color: #fbad84;
          border-radius: 50%;
          color: #fff;
          font-size: 32px;
          font-weight: 700;
          text-transform: uppercase;
        }
        &-name {
          margin-block: 8px 5px;
          font-size: 24px;
          color: var(--Text-Primary);
          font-weight: 600;
          line-height: 24px;
        }
        &-profession {
          font-size: 12px;
          font-weight: 400;
          letter-spacing: -0.24px;
          text-align: center;
          color: var(--Text-Secondary);
          padding-bottom: 24px;
          border-bottom: 1px solid #999;
          width: 100%;
        }
        &-stats {
          display: flex;
          gap: 24px;
          justify-content: space-between;
          margin-top: 16px;
          width: 100%;
          span {
            display: flex;
            flex-direction: column;
            gap: 4px;
            p {
              font-size: 12px;
              font-weight: 400;
              color: var(--Text-Secondary);
              margin-left: 4px;
            }
            blockquote {
              margin-top: 12px;
              width: 55px;
              border-bottom: 2px dotted var(--Text-Primary);
              margin-left: 4px;
            }
            div {
              display: flex;
              align-items: center;
              gap: 10px;
              font-size: 12px;
              font-weight: 600;
              color: var(--Text-Primary);
            }
          }
        }
      }
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    gap: 28px;
    @media only screen and (max-width: 90.2em) {
      margin-left: -45px;
    }
    @media only screen and (max-width: 84.8em) {
      margin-left: -70px;
    }
    @media only screen and (max-width: 83.68em) {
      margin-left: -110px;
    }
    @media only screen and (max-width: 80.87em) {
      margin-left: -150px;
    }
    @media only screen and (max-width: 78.43em) {
      margin-left: -170px;
    }
    @media only screen and (max-width: 77.12em) {
      margin-left: -215px;
    }
    @media only screen and (max-width: 74.3125em) {
      margin-top: 30px;
      margin-left: 0;
    }
    // @media only screen and (max-width: 1025px) {
    //   align-items: center;
    // }
    &-profile-strength {
      display: flex;
      flex-direction: column;
      gap: 4px;
      border-radius: 24px;
      background: #ee1d52;
      padding: 28px 24px 20px 24px;
      min-width: 330px;
      @media only screen and (max-width: 64.0625em) {
        width: 100%;
      }
      @media only screen and (max-width: 52.5em) {
        min-width: 120%;
        // max-width: 100%;
      }
      @media only screen and (max-width: 43.6875em) {
        min-width: 100%;
      }
      @media only screen and (max-width: 25.5625em) {
        min-width: 100%;
      }
      .title {
        font-size: 16px;
        font-weight: 400;
        line-height: 100.9%;
        color: #faf9ff;
      }
      .status {
        font-size: 32px;
        font-weight: 700;
        line-height: 100.9%;
        color: #faf9ff;
      }
      section {
        display: flex;
        flex-direction: column;
        margin-block: 2px 24px;
        align-items: end;
        color: #faf9ff;
        font-size: 16px;
        font-weight: 500;
        line-height: 150%; /* 24px */
        letter-spacing: -0.32px;
        gap: 12px;
        .progress-bar {
          display: flex;
          border-radius: 8px;
          background: #faf9ff;
          width: 100%;
          height: 8px;
          position: relative;
          span {
            position: absolute;
            top: -4px;
            left: 59%;
            display: flex;
            width: 16px;
            height: 16px;
            border: 2px solid #faf9ff;
            border-radius: 100%;
            background-color: #f47983;
            // transform: translate(-2%, -10%);
          }
          &-completion {
            // flex: 1;
            width: 10%;
            background-color: #f47983;
            border-radius: 8px 0px 0px 8px;
          }
        }
      }
      button {
        outline: none;
        border: none;
        background-color: transparent;
        border-radius: 24px;
        background: #faf9ff;
        padding: 16px 52px;
        color: var(--Text-Secondry);
        font-size: 18px;
        font-weight: 400;
        line-height: 100.9%;
        text-align: center;
        display: flex;
        justify-content: center;
        svg {
          margin-right: 12px;
        }
      }
    }
    &-profile-strength-high {
      display: flex;
      flex-direction: column;
      gap: 4px;
      border-radius: 24px;
      background: #1e1e2f;
      padding: 28px 24px 20px 24px;
      min-width: 330px;
      @media only screen and (max-width: 64.0625em) {
        width: 100%;
      }
      @media only screen and (max-width: 52.5em) {
        min-width: 120%;
      }
      @media only screen and (max-width: 43.6875em) {
        min-width: 100%;
      }
      @media only screen and (max-width: 25.5625em) {
        min-width: 100%;
      }
      .title {
        font-size: 16px;
        font-weight: 400;
        line-height: 100.9%;
        color: #feedcc;
      }
      .status {
        font-size: 32px;
        font-weight: 700;
        line-height: 100.9%;
        color: #feedcc;
      }
      section {
        display: flex;
        flex-direction: column;
        margin-block: 2px 24px;
        align-items: end;
        color: #fbad84;
        font-size: 16px;
        font-weight: 500;
        line-height: 150%; /* 24px */
        letter-spacing: -0.32px;
        gap: 12px;
        .progress-bar {
          display: flex;
          border-radius: 8px;
          background: #faf9ff;
          width: 100%;
          height: 8px;
          position: relative;
          span {
            position: absolute;
            top: -4px;
            left: 9%;
            display: flex;
            width: 16px;
            height: 16px;
            border: 2px solid #faf9ff;
            border-radius: 100%;
            background-color: #fbad84;
            // transform: translate(-2%, -10%);
          }
          &-completion {
            // flex: 1;
            width: 10%;
            background-color: #fbad84;
            border-radius: 8px 0px 0px 8px;
          }
        }
      }
      button {
        outline: none;
        border: none;
        background-color: transparent;
        border-radius: 24px;
        background: #faf9ff;
        padding: 16px 52px;
        color: var(--Text-Secondry);
        font-size: 18px;
        font-weight: 400;
        line-height: 100.9%;
        text-align: center;
        display: flex;
        justify-content: center;
        // align-items: center;
        svg {
          margin-right: 12px;
        }
      }
    }
    &-preferences {
      display: flex;
      flex-direction: column;
      gap: 4px;
      border-radius: 24px;
      background: #feedcc;
      padding: 28px 24px 20px 24px;
      max-width: 330px;
      @media only screen and (max-width: 74.3125em) {
        max-width: 100%;
      }
      @media only screen and (max-width: 1025px) {
        display: none;
      }
      .title {
        display: flex;
        gap: 12px;
        svg {
          width: 24px;
          height: 24px;
          min-width: 24px;
        }
        span {
          color: var(--Text-Primary);
          font-size: 22px;
          font-weight: 700;
          margin-top: -4px;
          // line-height: 100.9%;
          // p {
          //   overflow: hidden;
          //   display: -webkit-box;
          //   -webkit-line-clamp: 2;
          //   -webkit-box-orient: vertical;
          // }
        }
      }
      .content {
        margin-top: 20px;
        color: var(--Text-Primary);
        font-size: 18px;
        font-weight: 400;
        line-height: 100.9%;
        margin-bottom: -3px;
      }
      button {
        outline: none;
        border: none;
        background-color: transparent;
        border-radius: 24px;
        background: var(--Text-Primary);
        padding: 16px 52px;
        color: #faf9ff;
        font-size: 18px;
        font-weight: 400;
        line-height: 100.9%;
        text-align: center;
        display: flex;
        justify-content: center;
        // align-items: center;
        svg {
          margin-right: 12px;
          path {
            stroke: #faf9ff;
          }
        }
      }
    }
    &-slack {
      display: flex;
      flex-direction: column;
      border-radius: 24px;
      background: #f4f4f4;
      padding: 38px 24px 20px 24px;
      max-width: 330px;
      @media only screen and (max-width: 74.3125em) {
        max-width: 100%;
      }
      @media only screen and (max-width: 1025px) {
        display: none;
      }
      .title {
        color: var(--Text-Primary);
        font-size: 24px;
        font-weight: 700;
        margin-block: 29px 7px;
      }
      .content {
        color: var(--Text-Primary);
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 15px;
      }
      button {
        outline: none;
        border: none;
        background-color: transparent;
        border-radius: 24px;
        background: var(--Text-Primary);
        padding: 16px 52px;
        color: #faf9ff;
        font-size: 18px;
        font-weight: 400;
        line-height: 100.9%;
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
      }
    }
    &-mobile {
      display: none;
      @media only screen and (max-width: 1024px) {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 100px;
      }
      @media only screen and (max-width: 839px) {
        gap: 50px;
      }
      @media only screen and (max-width: 740px) {
        gap: 10px;
      }
      &-edit_preference-btn {
        button {
          background-color: #faf9ff;
          margin-top: 42px;
          padding: 16px 31px;
          color: #000000;
          border-radius: 24px;
          @media only screen and (max-width: 839px) {
            padding: 16px 18px;
            font-size: 14px;
            margin-top: 34px;
          }
          @media only screen and (max-width: 23.4375em) {
            font-size: 12px;
          }
        }
      }
      &-slackBtn {
        padding-left: 10px;
        border-radius: 24px;
        background-color: #faf9ff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 32px;
        @media only screen and (max-width: 839px) {
          width: 150px;
        }
      }
    }
  }
}

.main__dashboard {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  //   margin-left: 300px;

  &-recent-activities {
    /* Rectangle 4414 */

    // max-width: 1032px;
    margin-top: 40px;
    background: #faf9ff;
    border-radius: 24px;
    padding: 30px 30px;

    height: fit-content;
    max-height: 500px;
    @media only screen and (max-width: 84.8em) {
      width: 85% !important;
      overflow-x: scroll !important;
    }
    @media only screen and (max-width: 83.68em) {
      width: 75% !important;
      overflow-x: scroll !important;
    }
    @media only screen and (max-width: 77.12em) {
      width: 70% !important;
      overflow-x: scroll !important;
    }
    @media only screen and (max-width: 74.32em) {
      width: 100% !important;
      overflow-x: scroll !important;
    }
    @media only screen and (max-width: 52.4375em) {
      width: 416px !important ;
    }
    @media only screen and (max-width: 46.25em) {
      width: 356px !important;
    }
    @media only screen and (max-width: 43.6875em) {
      width: 100% !important;
    }
    @media only screen and (max-width: 25em) {
      width: 322px !important;
    }
    @media only screen and (max-width: 22.5em) {
      width: 300px !important;
    }
    @media only screen and (max-width: 21.25em) {
      width: 270px !important;
    }
  }

  &-recent-activities-container {
    margin-top: 20px;
  }
}

//   &-box {
//     display: flex;
//     flex-direction: column;
//     min-height: 100vh;
//     padding: 35px 28px 66px 64px;
//     max-width: 1400px;
//     width: 1400px;
//     margin-left: 300px;
//   }
//   &-heading {
//     display: flex;
//     width: 100%;
//     height: fit-content;
//     transform-origin: top;
//     justify-content: space-between;
//     padding-bottom: 10px;
//     border-bottom: 0.5px #999999 solid;
//     border-color: #999;
//     h1 {
//       font-size: 40px;
//       font-weight: 700;
//     }
//     button {
//       outline: none;
//       border: none;
//       cursor: pointer;
//       padding: 12px 40px;
//       border-radius: 55px;
//       border: 1px solid var(--Purple);
//       background: var(--Purple);
//       color: var(--Very-Lite-Purple, #faf9ff);
//       text-align: center;
//       font-size: 14px;
//       font-weight: 700;
//       height: min-content;
//       transition: all 0.3s;
//       &:hover {
//         background-color: var(--Text-Primary);
//       }
//     }
//   }
//   &-container {
//     display: flex;
//     flex-direction: column;
//     &-title {
//       font-size: 24px;
//       font-weight: 700;
//       color: var(--Text-Primary);
//       margin-bottom: 36px;
//     }
//     &-analytics {
//       display: flex;
//       align-items: center;
//       justify-content: space-between;
//       gap: 24px;
//       &-purple {
//         circle {
//           fill: #bdafeb;
//         }
//       }
//       &-lGreen {
//         circle {
//           fill: #91c7b1;
//         }
//       }
//       &-lPeach {
//         circle {
//           fill: #fdceb5;
//         }
//       }
//     }
//   }
// }

// .iti--allow-dropdown {
//   width: 100%;
// }

// /* Styles for the modal */

// .filterSection {
//   .select {
//     .ant-select-arrow {
//       margin-right: 20px;
//     }
//     // .ant-select-selector {
//     //   background-color: transparent;
//     //   height: 52px;
//     //   border-radius: 24px;
//     //   outline: none !important;
//     //   border-color: #999999 !important;
//     //   display: flex;
//     //   align-items: center;
//     //   font-size: 16px;
//     //   font-weight: 400;
//     //   padding-left: 20px;
//     //   .ant-select-selection-item {
//     //     span {
//     //       display: none !important;
//     //     }
//     //   }
//     //   &:hover {
//     //     border-color: rgba(102, 102, 102, 0.35) !important;
//     //     outline: none !important;
//     //   }
//     //   &:focus {
//     //     outline: none !important;
//     //     border-color: rgba(102, 102, 102, 0.35) !important;
//     //   }
//     //   font-family: "Space Grotesk";
//     //   .ant-select-selection-item {
//     //     padding-inline-start: 6px;
//     //   }
//     // }
//   }
//   input {
//     outline: none;
//     border: none;
//     border-radius: 12px;
//     border: 1px solid rgba(102, 102, 102, 0.35);
//     padding: 16px;
//     font-size: 16px;
//     font-weight: 400;
//     color: #1e1e2f;
//     background-color: transparent;
//     cursor: pointer;
//     margin-left: 20px;
//   }
//   .ant-dropdown-menu-item .ant-dropdown-menu-item-only-child {
//     padding: 10px 20px;
//   }
//   .roleType__option {
//     display: flex !important;
//     flex-direction: column !important;
//     gap: 6px !important;
//     transition: all 0.1s !important;
//     padding: 12px 32px !important;

//     &.ant-select-item-option-selected {
//       background-color: #5937cc !important;
//       color: white !important;
//       .ant-select-item-option-content {
//         span {
//           color: white !important;
//         }
//       }
//     }

//     &:hover {
//       background-color: #faf9ff !important;
//       .ant-select-item-option-content {
//         color: var(--Text-Primary) !important;
//         span {
//           color: #999 !important;
//         }
//       }
//     }

//     .ant-select-item-option-content {
//       display: flex !important;
//       flex-direction: column !important;
//       gap: 2px !important;
//       font-family: "Space Grotesk" !important;
//       font-size: 16px !important;
//       font-weight: 600 !important;
//       line-height: 150% !important;
//       transition: all 0.1s !important;
//       span {
//         color: #999 !important;
//         font-family: "Space Grotesk" !important;
//         transition: all 0.1s !important;
//         font-size: 14px !important;
//         font-weight: 400 !important;
//         line-height: 150% !important;
//         white-space: normal !important;
//       }
//     }
//   }
// }
