.studentCard {
    border-radius: 24px;
    background: #faf9ff;
    padding: 32px 16px 24px 16px;
    border-radius: 24px;
    max-width: 360px;
    width: 330px;
    display: flex;
    flex-direction: column;
    position: relative;

    @media only screen and (max-width: 1420px){
        width: 280px;
    
      }
      @media only screen and (max-width: 1240px){
        width:300px;
    
      }
      @media only screen and (max-width: 700px){
        width:100%;
        max-width: unset;
    
      }
      @media only screen and (max-width: 340px){
       width: 280px;
    
      }
    svg.bookmark{
        position: absolute;
        top: 18px;
        right: 16px;
        cursor: pointer;
    }
    svg.bookmarkChecked{
        path{
            stroke: #fdceb5;
            fill: #fdceb5;
        }
    }
    &__details {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 54px;
        img {
            width: 59px;
            height: 59px;
            border-radius: 50%;
        }
        &-container {
            display: flex;
            flex-direction: column;
            .name {
                color: var(--Text-Primary);
                font-size: 18px;
                font-weight: 600;
                line-height: 24px;
                display: flex;
                align-items: center;
                gap: 3px;
                svg {
                    width: 10px;
                    height: 10px;
                }
            }
            .profession {
                color: var(--Text-Secondry);
                font-size: 12px;
                font-weight: 400;
                letter-spacing: -0.24px;
            }
            .reviews {
                display: flex;
                align-items: center;
                gap: 5px;
                color: var(--Text-Secondry);
                font-size: 12px;
                font-weight: 400;
                letter-spacing: -0.24px;
            }
        }
    }
    &__stats {
        display: flex;
        gap: 16px;
        justify-content: space-between;
        section {
            display: flex;
            flex-direction: column;
            gap: 4px;
        }
        &-label {
            display: flex;
            gap: 10px;
            align-items: center;
            color: var(--Text-Primary);
            font-size: 12px;
            font-weight: 600;
        }
        color: var(--Text-Secondry);
        font-size: 12px;
        font-weight: 400;
    }
    &__buttons {
        display: flex;
        gap: 8px;
        margin-top: 36px;
        button {
            outline: none;
            border: none;
            flex: 1;
            border-radius: 24px;
            border: 1px solid var(--Text-Primary);
            background: var(--Text-Primary);
            color: #f2f4f7;
            text-align: center;
            font-size: 14px;
            font-weight: 700;
            padding-block: 10px;
        
            &:last-of-type{
                background-color: transparent;
                color: var(--Text-Primary);
            }
        }
    }
}

