.navbar {
    display: flex;
    align-items: center;
    max-width: 1440px;
    margin: 0 auto;
    width: 100%;
    padding: 0 96px;
    height: 78px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;

    @media only screen and (max-width: 620px) {
        padding: 0px 60px;
    }
    @media only screen and (max-width: 400px) {
        padding: 0px 40px;
    }
    @media only screen and (max-width: 350px) {
        padding: 0px 20px;
    }

    &__col {
        &-1 {
            display: flex;
            align-items: center;
        }
        &-2 {
            display: flex;
            gap: 11px;
        }
    }
    &__seperator {
        margin-inline: 75px 45px;
    }
    &__back-icon {
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        &:hover {
            transform: scale(1.1);
        }
        &.disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }
    &__links {
        display: flex;
        align-items: center;
        gap: 40px;
        a {
            color: var(--Grey);
            font-size: 14px;
            font-weight: 400;
            transition: all 0.2s ease-in-out;
            padding-bottom: 8px;
            border-bottom: 2px solid transparent;
        }
        a:hover {
            border-color: var(--Text-Primary);
            color: var(--Text-Primary);
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
        }
    }
    .account-links {
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: 12px;
    }
}
