.reasonForDecliningModal {
    padding: 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: white;
    border-radius: 24px;
    gap: 32px;
    position: relative;
    width: min-content;

    @media only screen and (max-width: 700px) {
        width: 100%;
        height: 100vh;
        border-radius: unset;
        padding: 40px;
    } 

    &_closeSquareLogo{
        position: absolute;
        top: 28.62px;
        right: 32px;
    }
   
    h1 {
        font-size: 40px;
        font-weight: 700;
        
        @media only screen and (max-width: 520px) {
            font-size: 32px;
          }
          @media only screen and (max-width: 420px) {
            font-size: 26px;
          }
          @media only screen and (max-width: 365px) {
            font-size: 20px;
          }
    }
    p{
        color: black;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        @media only screen and (max-width: 420px) {
            font-size: 14px;
          }
          @media only screen and (max-width: 350px) {
            font-size: 12px;
          }
    }
    form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;

        @media only screen and (max-width: 700px) {
            width: 100%;
         }
        label {
            display: flex;
            flex-direction: column;
            align-self: start;

        }
        input {
                outline: none;
                border: none;
                background-color: transparent;
                width: 650px;
                padding: 16px;
                border-radius: 6px;
                border: 1px solid #eee;
                font-size: 16px;
                font-weight: 400;
                @media only screen and (max-width: 700px) {
                   width: 100%;
                }
        }
    }
  

    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9999;
        background-color: #000000a7;
        width: 100vw;
        min-height: 100vh;
    }
    button {
        margin-top: 12px;
        outline: none;
        border: none;
        background-color: #5937CC;
        padding: 16px 173px;
        color: white;
        border-radius: 24px;
        font-size: 16px;
        cursor: pointer;
        border: 1px solid #4c8bf5;
        @media only screen and (max-width: 500px) {
            padding: 16px 110px;
         }
    }
    .disabled{
        background-color: grey;
        outline: none;
        border: none;
        pointer-events: none;
    }
}
