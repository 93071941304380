.scheduleMeetingModal {
  padding: 60px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: white;
  border-radius: 24px;
  position: relative;
  gap: 20px;
  width: 650px;

  @media only screen and (max-width: 700px) {
    width: 100%;
    height: 100vh;
    border-radius: unset;
    padding: 30px;
  } 

  &_closeSquareLogo{
    position: absolute;
    top: 28.62px;
    right: 32px;
  }
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: #000000a7;
    width: 100vw;
    min-height: 100vh;
  }

  &__error-case{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
    span{
      font-size: 16px;
      color: red;
    }
  }

  h1 {
    text-align: center;
    font-size: 30px;
    font-weight: 700;
  }
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    label {
      display: flex;
      flex-direction: column ;
      align-self: start;
    }
    input {
      outline: none;
      border: 1px solid rgba(102, 102, 102, 0.35);
      color: #999;
      background-color: transparent;
      width: 400px;
      padding: 16px;
      border-radius: 12px;
      font-size: 16px;
      font-weight: 400;
    }

    div{
      display: flex;
      align-self: flex-start;
      margin-bottom: 20px;
      gap: 12px;

    }
    .button1 {
      outline: none;
      background-color:  #1E1E2F;
      padding: 12px 40px;
      color: white;
      border-radius: 55px;
      font-size: 16px;
      cursor: pointer;
      border: 1px solid  #1E1E2F;

    }
    .button2 {
      outline: none;
      background-color: white;
      padding: 12px 20px;
      color: #999;
      border-radius: 55px;
      cursor: pointer;
      border: 1px solid var(--Color, #999);
    }
    .delBtn {
      outline: none;
      background-color: red;
      padding: 12px 20px;
      color: white;
      border-radius: 55px;
      cursor: pointer;
      //border: 1px solid var(--Color, #999);
    }
  }
}