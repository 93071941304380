.talent-profile {
  font-family: "Space Grotesk";
  //max-width: 1800px;
  .ant-tabs-tab {
    width: 120px;
  }

  .ant-tabs-ink-bar {
    height: 4px !important;
  }

  .ant-tabs-tab-btn {
    width: inherit;
    text-align: center;
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
  }

  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-top.ant-tabs-nav {
    color: #999999;
    border: 0.5px solid;
  }
}

.event-desc {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.tiles-grid {
  display: grid;
  flex: 1 1;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  min-height: 45px;
  overflow-y: auto;
  position: relative;
}

.horizontal-line-break {
  /* Line 29 */

  border: 0.5px solid;
  color: #999999;
  margin-top: 50px;
  @media only screen and (max-width: 68.75em) {
    width: 100% !important;
  }
}

// .ant-tabs-nav {
//     max-width: 1800px;
//     // width: 1800px;
// }

.see-all-title {
  display: flex;
  justify-content: end;
}

.vertical-line {
  border: 0.5px solid;
  color: #999999;
  /* border-left: 0.5px solid green; */
  height: auto;
  /* z-index: 10; */
  /* top: 30px; */
  margin-top: -16px;
  margin-left: 5%;
  @media only screen and (max-width: 68.75em) {
    margin-left: 0;
  }
}

.font-class {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 114.5%;
  /* or 27px */
  display: flex;
  align-items: center;

  color: #1e1e2f;
}
.no-scroll {
  overflow: hidden;
}
/* She/her */

.font-class-gender {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 114.5%;
  /* or 9px */
  display: flex;
  align-items: center;

  color: #344054;
}

.location-state {
  /* California,USA */

  width: 108px;
  height: 18px;

  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  color: #999999;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;

  @media only screen and (max-width: 25em) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.job-label {
  width: 81px;
  height: 18px;
}

/* Overview */

.job-tile {
  background: #faf9ff;
  border-radius: 24px;

  width: 400px;
  height: 372px;
  @media only screen and (max-width: 27.4375em) {
    width: 350px;
  }
  @media only screen and (max-width: 24.3125em) {
    width: 340px;
    padding: 20px;
  }
  @media only screen and (max-width: 21.8125em) {
    width: 300px;
    padding: 20px;
  }
}

.box-avatar {
  width: 200px;
  height: 205px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fbad84;
  color: #fff;
  font-size: 48px;
  font-weight: 700;
}

#button-group {
  display: flex;
  align-items: center;
  gap: 24px;

  @media only screen and (max-width: 520px) {
    width: 100%;
  }

  button {
    outline: none;
    border: none;
    cursor: pointer;
    padding: 12px 40px;
    border-radius: 55px;
    border: 1px solid var(--Purple);
    background: var(--Purple);
    color: var(--Very-Lite-Purple, #faf9ff);
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    height: min-content;
    transition: all 0.3s;
    &:hover {
      background-color: var(--Text-Primary);
    }

    &:first-of-type {
      color: var(--Purple);
      width: 220px;
      background-color: transparent;
      &:hover {
        background-color: var(--Purple);
        color: var(--Very-Lite-Purple, #faf9ff);
      }
      @media only screen and (max-width: 520px) {
        width: 100%;
      }
    }
  }
}
.blur-background {
  filter: blur(5px);
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 24px;
  backdrop-filter: blur(
    5px
  ); /* Adjust the px value to increase/decrease blur */
}

.modal-content {
  position: relative;
  background: white;
  //   padding: 2px;
  border-radius: 34px !important;
  width: 80%;
  max-width: 750px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.close-button {
  position: absolute;
  top: 15px !important;
  right: 17px !important;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  //   background-color: #999999;
  width: 32px;
  border-radius: 24px;
}

@media only screen and (max-width: 300em) {
  #ButtonSection {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  #ButtonSectionMobile {
    display: none;
  }
}

@media only screen and (max-width: 95.5em) {
  .tabs_container {
    margin-right: 100px !important;
    margin-left: 100px !important;
  }
}
@media only screen and (max-width: 90.2em) {
  .tabs_container {
    margin-right: 100px !important;
    margin-left: 100px !important;
  }
}
@media only screen and (max-width: 86.8em) {
  .tabs_container {
    margin-right: 100px !important;
    margin-left: 100px !important;
  }
}
@media only screen and (max-width: 83.68em) {
  .tabs_container {
    margin-right: 100px !important;
    margin-left: 100px !important;
  }
}
@media only screen and (max-width: 74.32em) {
  .tabs_container {
    margin-right: 100px !important;
    margin-left: 100px !important;
  }
}
@media only screen and (max-width: 72.3125em) {
  .tabs_container {
    margin-right: 70px !important;
    margin-left: 70px !important;
  }
}
@media only screen and (max-width: 70.4375em) {
  .tabs_container {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }
}
@media only screen and (max-width: 68.75em) {
  #MainDiv {
    flex-direction: column;
  }
  #MainDivFirstHalf {
    max-width: 100% !important;
  }
  #MainDivSecondHalf {
    margin-left: 0 !important;
  }
  #ProfileTopSectionDiv {
    margin-left: 60px !important;
  }
  // #ProfileTopSectionDiv-partner {
  //     margin-left: 60px !important;
  // }
  #SocialMediaAndButtonDiv {
    margin-left: 50px;
  }
}

@media only screen and (max-width: 32.5em) {
  #SocialMediaAndButtonDiv {
    margin-left: 20px;
  }
}
@media only screen and (max-width: 59.375em) {
  .user-avatar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
  }
  #ProfileTopSectionDiv {
    margin-right: 45px !important;
  }
  #SocialMediaAndButtonDiv {
    justify-content: start;
  }
  .opportunities-heading {
    font-size: 28px !important;
  }
}

@media only screen and (max-width: 46.25em) {
  .tabs_container {
    margin-right: 100px !important;
    margin-left: 100px !important;
  }
  .opportunities-heading {
    font-size: 24px !important;
  }
}

@media only screen and (max-width: 41.875em) {
  #SocialMediaAndButtonDiv {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  #ButtonSection {
    display: flex;
  }
  #ButtonSectionMobile {
    display: none;
  }
}

@media only screen and (max-width: 39.5625em) {
  .tabs_container {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }
}
@media only screen and (max-width: 32.0625em) {
  .ant-tabs-tab {
    margin: 0 !important;
  }
  #ProfileTopSectionDiv {
    margin-left: 10px !important;
    margin-right: 0 !important;
  }
  #SocialMediaIcons {
    margin-right: 0 !important;
  }
  .profile-image-avatar {
    width: 150px !important;
    height: 150px !important;
    margin-top: 50px;
  }
  #SocialMediaAndButtonDiv {
    margin-top: 25px !important;
    margin-right: 45px;
  }
  .user-avatar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
  }
  .profile-banner-img {
    height: 150px;
    object-fit: cover;
  }
  .profile-image-avatar-div {
    margin-top: -110px !important;
  }
}

@media only screen and (max-width: 32.5em) {
  #SocialMediaAndButtonDiv {
    margin-top: 10px !important;
    margin-right: 30px;
  }
}

@media only screen and (max-width: 30.3125em) {
  .talent-profile-name {
    font-size: 22px !important;
  }
  #SocialMediaIcons {
    svg {
      width: 28px !important;
      height: 28px !important;
    }
  }
  .editProfile-btn {
    width: 24px !important;
    height: 24px !important;
    margin-top: 5px;
    margin-left: 5px;
    svg {
      width: 26px !important;
      height: 26px !important;
    }
  }
  #ButtonSectionMobile {
    margin-top: 0;
  }
}

@media only screen and (max-width: 28.4375em) {
  .ant-tabs-tab-btn {
    font-size: 12px !important;
  }
  .tabs_container {
    margin-right: 30px !important;
    margin-left: 30px !important;
  }
  #SocialMediaIcons {
    gap: 4px;
    svg {
      width: 24px !important;
      height: 24px !important;
    }
  }
}

.reviews-section {
  display: flex;
  justify-content: start;
  gap: 15px;
  flex-wrap: wrap;
  @media only screen and (max-width: 56.5em) {
    justify-content: center;
  }
}

.review-paragraph {
  height: 150px;
  overflow: auto;
  @media only screen and (max-width: 24.3125em) {
    padding: 20px;
  }
}

.review-rating {
  @media only screen and (max-width: 24.3125em) {
    padding: 20px;
  }
}

.review-avatar-sec {
  padding-bottom: 15px;
}

.editProfile-btn {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #faf9ff;
}
//   @media only screen and (max-width:  25.5625em) {
//     .tabs_container {
//         margin-right: 100px !important;
//         margin-left: 100px !important;
//     }
//   }
//   @media only screen and (max-width:  23.75em) {
//     .tabs_container {
//         margin-right: 100px !important;
//         margin-left: 100px !important;
//     }
//   }

// @media only screen and (max-width: 1445px) {
//     .talent-profile {
//         max-width: 1800px;
//     }
// }
