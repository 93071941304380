.adminLogin {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    &__logo{
        padding: 50px 96px 44px 96px;

        @media only screen and (max-width: 500px){
            padding: 26px 96px 15px 40px;
        }
        @media only screen and (max-width: 400px){
            padding: 26px 96px 15px 30px;
        }
        @media only screen and (max-width: 350px){
            padding: 26px 96px 15px 20px;
        }
    
   
        

    }
    &__container {
        display: flex;
        flex-direction: column;
        padding: 70px 159px;
        border-radius: 24px;
        background: var(--Very-Lite-Purple);
        width: 740px;
        margin-top: auto;
        margin-inline: auto;
        gap: 16px;

        @media only screen and (max-width: 740px){
            width: 100%;
        }
        @media only screen and (max-width: 620px){
            padding: 70px 60px;
        }
        @media only screen and (max-width: 500px){
            padding: 40px 40px;
            background: none;
            margin-top: 0px;
            margin-bottom: 0px;
        }
        @media only screen and (max-width: 400px){
            padding: 40px 30px 0px;
        }
        @media only screen and (max-width: 350px){
            padding: 40px 20px 0px;
        }
       
        h2 {
            align-self: center;
            font-size: 40px;
            font-weight: 700;
            color: var(--Text-Primary);

            
            @media only screen and (max-width: 690px){
                font-size: 32px;
            }

            @media only screen and (max-width: 620px){
                font-size: 28px;
            }
            @media only screen and (max-width: 500px){
                align-self: self-start;
                margin-bottom: 60px;

            }
            @media only screen and (max-width: 390px){
                font-size: 26px;
            }
        }

        form {
            display: flex;
            flex-direction: column;
            gap: 32px;
            .select {
                .ant-select-selector {
                    background-color: transparent;
                    height: 56px;
                    border-radius: 12px;
                    outline: none !important;
                    border-color: rgba(102, 102, 102, 0.35) !important;
                    .ant-select-selection-item {
                        span {
                            display: none !important;
                        }
                    }
                    &:hover {
                        border-color: rgba(102, 102, 102, 0.35) !important;
                        outline: none !important;
                    }
                    &:focus {
                        outline: none !important;
                        border-color: rgba(102, 102, 102, 0.35) !important;
                    }
                    font-family: "Space Grotesk";
                    .ant-select-selection-item {
                        padding-inline-start: 6px;
                    }
                }
            }
            label {
                display: flex;
                flex-direction: column;
                gap: 4px;
                span {
                    font-size: 16px;
                    font-weight: 400;
                    color: var(--Text-Primary);
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    svg {
                        cursor: pointer;
                    }
                }
                input {
                    outline: none;
                    border: none;
                    border-radius: 12px;
                    border: 1px solid rgba(102, 102, 102, 0.35);
                    padding: 16px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #1e1e2f;
                    background-color: transparent;
                }
            }
            button {
                margin-top: 8px !important;
            }
            p {
                font-size: 13px;
                font-weight: 400;
                text-align: center;
                color: var(--Text-Primary);
                a {
                    color: #5937cc;
                    text-decoration: underline;
                }
            }
            a {
                color: var(--Text-Primary);
                text-align: center;
                font-size: 14px;
                font-weight: 700;
                text-decoration-line: underline;
                mask-type: 28px;
            }
        }
    }
}
