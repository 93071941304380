.employerExplore {
  &-select {
    .ant-select-selector {
      background-color: transparent;
      height: 56px;
      border-radius: 12px;
      outline: none !important;
      border-color: rgba(102, 102, 102, 0.35) !important; 
      
      @media only screen and (max-width: 980px){
        width: 100%;
     
       }
      &:hover {
        border-color: rgba(102, 102, 102, 0.35) !important;
        outline: none !important;
      }
      &:focus {
        outline: none !important;
        border-color: rgba(102, 102, 102, 0.35) !important;
      }
      font-family: "Space Grotesk";
      .ant-select-selection-item {
        padding-inline-start: 6px;
      }
      .ant-select-selection-overflow {
        // padding-inline-start: 6px;
        max-height: 50px;
        overflow: scroll;
      }
    }
  }
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;

  &__box {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 35px 64px 66px 64px;
    max-width: 1240px;
    min-width: 1240px;
    @media only screen and (max-width: 95.5em) {
      max-width: 1150px;
      min-width: 1150px;
    } 
    @media only screen and (max-width: 90.2em) {
      max-width: 1115px;
      min-width: 1115px;
    } 
    @media only screen and (max-width: 87.75em) {
      max-width: 1060px;
      min-width: 1060px;
      padding: 35px 14px 66px 64px;
    } 
    @media only screen and (max-width: 83.68em) {
      max-width: 900px;
      min-width: 900px;
    } 
    @media only screen and (max-width: 74.32em) {
      max-width: 800px;
      min-width: 800px;
    } 
    @media only screen and (max-width: 68.062em) {
      max-width: 650px;
      min-width: 650px;
    } 
    @media only screen and (max-width: 58.6875em) {
      max-width: 550px;
      min-width: 550px;
    }
    @media only screen and (max-width: 52.4375em) {
      max-width: 450px;
      min-width: 450px;
    }  
    @media only screen and (max-width:  46.25em) {
      max-width: 400px;
      min-width: 400px;
    }
    @media only screen and (max-width:  43.6875em) {
      max-width: unset;
      min-width: 100%;
      padding: 35px 34px 66px 34px;
    }
    @media only screen and (max-width:  24.375em) {
      max-width: unset;
      min-width: 100%;
      padding: 35px 20px 66px 20px;
    }
   
  }
  &__heading {
    display: flex;
    width: 100%;
    height: fit-content;
    transform-origin: top;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 0.5px #999999 solid;
    border-color: #999;
    margin-bottom: 22px;
    h1 {
      font-size: 40px;
      font-weight: 700;
      @media only screen and (max-width:  52.375em) {
        font-size: 32px;
      }
      @media only screen and (max-width:  31.25em) {
        font-size: 24px;
      }
    }
  }
  &__recommendations-container {
    display: flex;
    width: 100%;

    flex-direction: column;
    gap: 24px;
    &-title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      div {
        color: var(--Text-Primary);
        font-size: 32px;
        font-weight: 700;
        line-height: 100.9%;
        display: flex;
        align-items: center;
        gap: 8px;
        svg {
          margin-bottom: -4px;
        }
        @media only screen and (max-width:  31.25em) {
          font-size: 16px;
        }
      }
    }
  }
}

.tagStyle{
  display:flex;
  gap:8px;
  padding:2px 10px;
  border-radius: 22px;
  color: #344054 !important;
  font-size: 10px;
  font-weight: 400;
}


.select-heading{
  @media only screen and (max-width:  58.625em) {
    font-size: 13px !important;
  }
}

.employer-explore-searchbar .main__dashboard-container-analytics{
  @media only screen and (max-width:  1370px) {
    flex-wrap: nowrap;
  }

}

.employer-explore-searchTags{
  // margin-left: 20px;
  display: flex;
  width: 400px;
  gap: 10px;
  padding-bottom: 20px;
  overflow-x: auto;
  @media only screen and (max-width: 68.0625em) {
    width: 300px
  } 
  @media only screen and (max-width: 47.75em) {
    width: 250px;
    margin-left: 0 !important;
  } 
  @media only screen and (max-width:  25.625em) {
    width: 200px
  } 
}

.employer-explore-searchTags{
  cursor: pointer;
}

.employer-explore-searchTags::-webkit-scrollbar {
  cursor: pointer;
  width: 5px !important;
  background-color: #f5f5f5;
}

 ///////////////////////// for mobile view 
.mobile-collapse {
  display: none;
}

@media screen and (max-width: 700px) {
  .mobile-collapse {
    display: block;
  }
  .main__dashboard-container-analytics .filterSection {
    display: none !important;
  }
}


.ant-collapse-content-box {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.bookmark{
  cursor: pointer;
}
.bookmarkChecked{
  path{
      stroke: #fdceb5;
      fill: #fdceb5;
  }
}