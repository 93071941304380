.verifiedEmployer {
  display: flex;
  flex-direction: column;
  width: 330px;
  max-width: 330px;
  min-width: 320px;
  border-radius: 24px;
  background: var(--Very-Lite-Purple);
  position: relative;
  flex: 1;
  height: 400px;
  @media only screen and (max-width: 1025px) {
    width: 315px;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    max-width: unset;
  }

  &__bookmark {
    position: absolute;
    top: 16px;
    right: 24px;
    cursor: pointer;
    background-color: white;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
  }

  svg.bookmarkChecked {
    path {
      stroke: #fdceb5;
      fill: #fdceb5;
    }
  }

  .profile {
    border: 1px solid white;
    position: absolute;
    top: 46px;
    left: 16px;
    border-radius: 100px;
    object-fit: contain;
    aspect-ratio: 1/1;
    width: 78px;
    background-color: white;
  }

  .image {
    height: 150px;
    background-size: cover;
    background-position: center;
    border-radius: 24px 24px 0 0;
  }

  &__content {
    display: flex;
    padding: 16px 24px;
    flex-direction: column;

    .name {
      font-size: 24px;
      font-weight: 600;
      line-height: 24px;
      color: var(--Text-Primary);
      display: flex;
      align-items: center;

      gap: 8px;

      span {
        font-size: 8px;
        font-weight: 400;
        color: var(--Dark-Grey);
        align-self: flex-end;
        line-height: 2;
      }
    }

    .tags {
      margin-block: 8px 16px;
      font-size: 12px;
      font-weight: 400;
      line-height: 24px;
      color: var(--Text-Secondary);
    }

    .details {
      display: flex;
      flex-direction: column;
      gap: 8px;

      span {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 12px;
        font-weight: 300;
        color: var(--Text-Secondary);
        line-height: 24px;
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 7px;
      margin-top: 30px;

      button {
        border-radius: 55px;
        background: var(--Text-Primary);
        outline: none;
        border: none;
        color: var(--Lite-Grey);
        font-size: 14px;
        font-weight: 700;
        flex: 1;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 42px;
        border: 1px solid var(--Text-Primary);

        &.invert {
          background-color: transparent;
          color: var(--Text-Primary);
        }
      }
    }
  }
}

.employerSaved {
  background-color: #fdceb5;
}
