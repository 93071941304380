.Verification {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    &__container {
        margin-top: 120px;
        display: flex;
        width: 400px;
        margin-inline: auto;
        align-items: center;
        flex-direction: column;

        @media only screen and (max-width: 420px){
            width: 100%;
            padding: 70px 40px;
        }
        @media only screen and (max-width: 390px){
            padding: 70px 20px;
            margin-top: 0px;
        }

        h2 {
            color: var(--Text-Primary);
            font-size: 40px;
            font-weight: 700;
            line-height: 115%;
            text-align: center;
            margin-top: 8px;
            margin-bottom: 32px;

            @media only screen and (max-width: 690px){
                font-size: 32px;
            }

            @media only screen and (max-width: 620px){
                font-size: 28px;
            }
            @media only screen and (max-width: 390px){
                font-size: 26px;
            }
        }
        p {
            font-size: 14px;
            font-weight: 500;
            color: var(--Text-Primary);
            text-align: center;

            @media only screen and (max-width: 390px){
                font-size: 10px;
            }
        }
        form {
            width: 100%;
            margin-top: 32px;
            label {
                display: flex;
                flex-direction: column;
                gap: 11px;
                font-size: 16px;
                font-weight: 400;
                color: var(--Text-Primary);
                span {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }
            input {
                outline: none;
                border: none;
                border-radius: 12px;
                border: 1px solid rgba(102, 102, 102, 0.35);
                padding: 16px;
                font-size: 16px;
                font-weight: 400;
                color: #1e1e2f;
                background-color: transparent;
                margin-bottom: 12px;
            }

            p {
                margin-top: 19px;
                font-size: 13px;
                font-weight: 500;
                text-align: center;
                color: var(--Text-Primary);

                @media only screen and (max-width: 350px){
                    font-size: 10px;
                }

                a {
                    color: #5937cc;
                    text-decoration: underline;
                }
            }
        }
        .success {
            display: flex;
            flex-direction: column;
            align-items: center;
            svg {
                margin-right: -42px;
            }
            .title {
                color: var(--Text-Primary);
                text-align: center;
                font-size: 40px;
                font-weight: 700;
                line-height: 114.5%;
                margin-block: 24px 15px;

                @media only screen and (max-width: 390px){
                    font-size: 24px;
                }
            }
            span {
                color: var(--Text-Primary);
                text-align: center;
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 32px;
            }
            button {
                outline: none;
                border: none;
                border-radius: 24px;
                background: #5937cc;
                padding: 16px;
                color: #f2f4f7;
                font-size: 14px;
                font-weight: 700;
                width: 100%;
                transition: all 0.3s ease-in-out;

                &:hover {
                    background-color: var(--Text-Primary);
                }
            }
        }
    }
}
