.employerATS__atsPage {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    gap: 24px;

    &-box {
      display: flex;
      flex-direction: column;
      min-height: 100vh;
      padding: 35px 64px 66px 64px;
      max-width: 1240px;
      min-width: 1240px;

      @media only screen and (max-width: 1522px){
        max-width: 1140px;
        min-width: 1140px;
    
      }
      @media only screen and (max-width: 1422px){
        max-width: 990px;
        min-width: 990px;
    
      }
      @media only screen and (max-width: 1270px){
        max-width: 790px;
        min-width: 790px;
      }
      @media only screen and (max-width: 1070px){
        max-width: 720px;
        min-width: 720px;
      }
      @media only screen and (max-width: 1000px){
        max-width: 650px;
        min-width: 650px;
      }
      @media only screen and (max-width: 930px){
        max-width: 600px;
        min-width: 600px;
      }
      @media only screen and (max-width: 880px){
        max-width: 550px;
        min-width: 550px;
      }
      @media only screen and (max-width: 830px){
        max-width: 500px;
        min-width: 500px;
        padding: 64px 64px 66px 64px;
      }
      @media only screen and (max-width: 780px){
        max-width: 480px;
        min-width: 480px;
        padding: 70px 64px 66px 64px;
      }
      @media only screen and (max-width: 760px){
        max-width: 430px;
        min-width: 430px;
        padding: 70px 44px 66px 44px;
      }
      @media only screen and (max-width: 700px){
        max-width: 100%;
        min-width: 100%;
        padding: 35px 64px 66px 64px;

      }
      @media only screen and (max-width: 400px){
        max-width: 100%;
        min-width: 100%;
        padding: 40px 34px 66px 34px;

      }
    }
    &-heading {
      display: flex;
      width: 100%;
      height: fit-content;
      transform-origin: top;
      justify-content: space-between;
      padding-bottom: 10px;
      border-bottom: 0.5px #999999 solid;
      border-color: #999;
      h1 {
        font-size: 40px;
        font-weight: 700;

        @media only screen and (max-width: 830px){
          font-size: 32px;
        }
        @media only screen and (max-width: 780px){
          font-size: 30px;
        }
        @media only screen and (max-width: 700px){
          font-size: 36px;
        }
        @media only screen and (max-width: 400px){
          font-size: 30px;
        }
      }
    }
    &-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 25%;
       
      &-inputs{
        display: flex;
        flex-direction: column;
        justify-content: start;
        gap: 20px;

        @media only screen and (max-width: 880px){
          width: 100%;
        }

        button{
            width: 129px;
            height: 42px;
            padding: 12px 40px;
            background-color: rgba(89, 55, 204, 1);
            color: white;
            border-radius: 55px ;

            @media only screen and (max-width: 880px){
              width: 100%;
              margin-top: 20px;
            }
        }
      }
      input{
        width: 504px;
        height: 56px;
        border: 1px solid rgba(102, 102, 102, 0.35);
        border-radius: 12px;
        padding: 10px;

        @media only screen and (max-width: 880px){
          width: 100%;
        }
      }
    }
}

.employerATS__atsPage-container-integrationMessage button.formButton{
  @media only screen and (max-width: 390px){
    margin-top: 20px;
  }

} 

  
 