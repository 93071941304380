.recommendedJob {
  border-radius: var(--corner-radius-corner-radius-big, 24px);
  border: 0px solid var(--grey, #999);
  background: var(--very-lite-purple, #faf9ff);
  padding: 72px 20px 20px 20px;
  width: 330px;
  max-width: 330px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  @media only screen and (max-width: 1025px) {
    width: 315px;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    max-width: unset;
  }
  svg.bookmark {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
  }
  svg.bookmarkChecked{
    path{
        stroke: #fdceb5;
        fill: #fdceb5;
    }
}
  &__match {
    position: absolute;
    top: 24px;
    left: 34px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    font-size: 10px;
    font-weight: 400;
    color: var(--Text-Primary);
    .progress {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: radial-gradient(closest-side, white 79%, transparent 80% 100%),
        conic-gradient(#5937cc 75%, #5937cc33 0);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: var(--Text-Primary);
      font-weight: 700;
    }
  }
  .name {
    font-size: 16px;
    font-weight: 400;
    margin-block: 12px;
    color: var(--Text-Secondary);
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .rating {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    margin-block: 8px 0;
    color: var(--Text-Primary);
    padding-bottom: 12px;
    max-height: 36px;

    max-width: 260px;
    text-wrap: nowrap;
    overflow-x: auto;
  }
  .line {
    background: rgba(153, 153, 153, 0.8);
    width: 100%;
    height: 2px;
    display: block;
    margin-bottom: 8px;
    transform: scaleY(0.25);
  }
  .details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    div {
      display: flex;
      align-items: center;
      gap: 4px;
      svg {
        // width: 100%;
      }
      span {
        font-size: 9px;
        color: var(--Text-Primary);
        white-space: nowrap;
      }
    }
  }
  .buttons {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 7px;
    margin-top: 30px;
    button {
      border-radius: 55px;
      background: var(--Text-Primary);
      outline: none;
      border: none;
      color: var(--Lite-Grey);
      font-size: 14px;
      font-weight: 700;
      flex: 1;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 42px;
      border: 1px solid var(--Text-Primary);
      &.invert {
        background-color: transparent;
        color: var(--Text-Primary);
      }
    }
  }
}

.recommendedJobSaved{
  background-color: #fdceb5;
 }

 .recommendedJob__profile {
  border: 1px solid white;
  border-radius: 100px;
  aspect-ratio: 1/1;
  width: 78px;
  background-color: white;
}