.job_container {
  display: flex;
  flex-direction: column;
  padding: 20px 150px;
  max-width: 1800px;
  @media only screen and (max-width:  50em) {
    padding: 20px 65px;
  }
  @media only screen and (max-width:  25em) {
    padding: 20px 35px;
  }
  &_section-1 {
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
    border-bottom: 0.5px solid #999999;
    @media only screen and (max-width:  31.25em) {
      justify-content: center;
      align-items: center;
    }
  }
  &_title {
    margin-top: 20px;

    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    color: #000000;
  }
  &_name {
    display: flex;
    gap: 20px;
    font-weight: 300;
    font-size: 16px;
    color: #344054;
    margin-top: 10px;
    @media only screen and (max-width:  31.25em) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  &_tribaja {
    margin-left: 6px;
    color: #999999;
    font-size: 8px;
    font-weight: 400;
  }
  &_buttons {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
    margin-top: 30px;
    button {
      border-radius: 55px;
      background: #5937cc;
      outline: none;
      border: none;
      color: var(--Lite-Grey);
      font-size: 14px;
      font-weight: 700;
      width: 150px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 42px;
      border: 1px solid var(--Text-Primary);
      &.invert {
        background-color: transparent;
        color: var(--Text-Primary);
      }
    }
  }
  &_section-2 {
    display: flex;
    gap: 40px;
    margin-top: 35px;
    flex-wrap: wrap;
  }

  &_card {
    padding: 16px;
    gap: 4px;

    background: #fdceb5;
    border-radius: 24px;
    //height: 70px;
    display: flex;
    flex-direction: column;

    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: #344054;
    //overflow: scroll;
  }

  &_section-3 {
    display: flex;
    border-bottom: 0.5px solid #999999;
    border-top: 0.5px solid #999999;
    margin-top: 40px;
    @media only screen and (max-width:  43.75em) {
      flex-direction: column;
    }

    &-left-half {
      //   display: flex;
      //   gap: 14px;
      //   margin-top: 35px;
      padding-top: 40px;
      padding-bottom: 40px;
      width: 50%;
      padding-right: 40px;
    }
    &-vertical-line {
      border: 0.5px solid;
      color: #999999;
      /* border-left: 0.5px solid green; */
      height: auto;
      /* z-index: 10; */
      /* top: 30px; */

      //   margin-left: 5%;
    }

    &-right-half {
      //   display: flex;
      //   gap: 14px;
      //   margin-top: 35px;
      padding-top: 40px;
      padding-bottom: 40px;
      width: 50%;
      padding-left: 40px;
      @media only screen and (max-width:  43.75em) {
        padding-left: 0;
        width: 100%;
      }
      .skills {
        border-bottom: 0.5px solid #999999;
        padding-bottom: 40px;
      }
    }
  }
  .skills-sec{
    @media only screen and (max-width:  33.75em) {
      flex-direction: column ;
    }
  }
}
