.pagination{
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid  #999999;
  margin-top: 50px ;
  padding-top: 30px;

  &__leftArrow{
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    color: #999999;
    font-size: 14px;
    font-weight: 400;
  }
  &__rightArrow{
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    color: #999999;
    font-size: 14px;
    font-weight: 400;
  }
  .ant-pagination-options{
    display: none;
  }
}