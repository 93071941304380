.selectProfile {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    &__container {
        display: flex;
        width: 400px;
        margin-inline: auto;
        align-items: center;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        h2 {
            font-size: 40px;
            font-weight: 700;
            line-height: 114.5%;
            color: var(--Text-Primary);
            margin-block: 22.5px 60px;
        }
        form {
            width: 100%;
            label {
                display: flex;
                flex-direction: column;
                gap: 11px;
                font-size: 16px;
                font-weight: 400;
                color: var(--Text-Primary);
                span {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }
            .select {
                .ant-select-selector {
                    background-color: transparent;
                    height: 56px;
                    border-radius: 12px;
                    outline: none !important;
                    border-color: rgba(102, 102, 102, 0.35) !important;
                    .ant-select-selection-item {
                        span {
                            display: none !important;
                        }
                    }
                    &:hover {
                        border-color: rgba(102, 102, 102, 0.35) !important;
                        outline: none !important;
                    }
                    &:focus {
                        outline: none !important;
                        border-color: rgba(102, 102, 102, 0.35) !important;
                    }
                    font-family: "Space Grotesk";
                    .ant-select-selection-item {
                        padding-inline-start: 6px;
                    }
                }
            }
        }
    }
}
