.eventPosted {
    width: 100vw;
    min-height: 100vh;
    background: var(--Very-Lite-Purple, #faf9ff);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    svg {
        margin-bottom: 54px;
    }
    h1 {
        color: var(--dark, #1e1e2f);
        text-align: center;
        font-size: 40px;
        font-weight: 700;
    }
    button {
        margin-top: 60px;
        padding: 18px 70px;
        cursor: pointer;
        border: none;
        outline: none;
        border-radius: 55px;
        background: #5937cc;
        color: #faf9ff;

        &:hover {
            background-color: #1e1e2f;
        }
    }
}
