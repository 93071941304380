.talentExplore {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;

  &__box {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 35px 64px 66px 64px;
    max-width: 1240px;
    min-width: 1240px;
    @media only screen and (max-width: 95.5em) {
      max-width: 1150px;
      min-width: 1150px;
    }
    @media only screen and (max-width: 90.2em) {
      max-width: 1050px;
      min-width: 1050px;
    }
    @media only screen and (max-width: 86.8em) {
      padding: 35px 64px 66px 34px;
    }
    @media only screen and (max-width: 83.68em) {
      max-width: 900px;
      min-width: 900px;
    }
    @media only screen and (max-width: 74.32em) {
      max-width: 800px;
      min-width: 800px;
    }
    @media only screen and (max-width: 68.062em) {
      max-width: 650px;
      min-width: 650px;
    }
    @media only screen and (max-width: 58.6875em) {
      max-width: 550px;
      min-width: 550px;
    }
    @media only screen and (max-width: 52.4375em) {
      max-width: 450px;
      min-width: 450px;
    }
    @media only screen and (max-width: 46.25em) {
      max-width: 400px;
      min-width: 400px;
    }
    @media only screen and (max-width: 43.6875em) {
      max-width: unset;
      min-width: 100%;
      padding: 35px 34px 66px 34px;
    }
    @media only screen and (max-width: 25.5625em) {
      max-width: 350px;
      min-width: 350px;
      margin-top: 20px;
      padding: 0;
    }
    @media only screen and (max-width: 23.75em) {
      max-width: 300px;
      min-width: 300px;
    }
  }
  &__heading {
    display: flex;
    width: 100%;
    height: fit-content;
    transform-origin: top;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 0.5px #999999 solid;
    border-color: #999;
    margin-bottom: 22px;
    h1 {
      font-size: 40px;
      font-weight: 700;
      @media only screen and (max-width: 52.375em) {
        font-size: 32px;
      }
      @media only screen and (max-width: 31.25em) {
        font-size: 24px;
      }
    }
  }
  &__recommendations-container {
    display: flex;
    width: 100%;
    margin-top: 80px;
    flex-direction: column;
    gap: 24px;
    &-title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      div {
        color: var(--Text-Primary);
        font-size: 32px;
        font-weight: 700;
        line-height: 100.9%;
        display: flex;
        align-items: center;
        gap: 8px;
        svg {
          margin-bottom: -4px;
        }
        @media only screen and (max-width: 31.25em) {
          font-size: 16px;
        }
      }
    }
  }
  .event-desc {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.bookmark {
  cursor: pointer;
}
.bookmarkChecked {
  path {
    stroke: #fdceb5;
    fill: #fdceb5;
  }
}

.explore-searchbar {
  @media only screen and (max-width: 68.0625em) {
    width: 100% !important;
  }
}

.explore-searchTags {
  margin-left: 20px;
  display: flex;
  width: 400px;
  gap: 10px;
  padding-bottom: 20px;
  overflow-x: auto;
  @media only screen and (max-width: 68.0625em) {
    width: 300px;
  }
  @media only screen and (max-width: 25.625em) {
    width: 200px;
  }
}

.explore-searchTags::-webkit-scrollbar {
  width: 5px !important;
  background-color: #f5f5f5;
}

.main__dashboard-container-analytics {
  @media only screen and (max-width: 84.375em) {
    flex-direction: column;
    justify-content: start !important;
    align-items: start !important;
  }
}

.searchTags-sec {
  @media only screen and (max-width: 52.4375em) {
    flex-direction: column;
    gap: 10px;
  }
}
/* JobTile Component */
.blur-background {
  filter: blur(5px);
}

/* Modal Component */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 24px;
}

.modal-content {
  position: relative;
  background: white;
  padding: 2px;
  border-radius: 10px;
  width: 80%;
  max-width: 750px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
  // background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  background-color: #999999;
}
