.requestModalTalent {
    padding: 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: white;
    border-radius: 24px;
    gap: 32px;
    position: relative;
    width: 650px;

    @media only screen and (max-width: 500px) {
        width: 100%;
        height: 100vh;
        border-radius: unset;
        padding: 40px;
    } 

    &_closeSquareLogo{
        position: absolute;
        top: 28.62px;
        right: 32px;
    }
    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9999;
        background-color: #000000a7;
        width: 100vw;
        min-height: 100vh;
    }
   
    h1 {
        text-align: center;
        font-size: 34px;
        font-weight: 700;  
        @media only screen and (max-width: 400px) {
            font-size: 26px;
        }       
    }
    p{
        color: black;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
    }
    button {
            margin-top: 12px;
            outline: none;
            background-color: #5937CC;
            padding: 16px 173px;
            color: white;
            border-radius: 24px;
            font-size: 16px;
            cursor: pointer;
            border: 1px solid #4c8bf5;
            @media only screen and (max-width: 500px) {
                padding: 16px 110px;
             }
        }
    }
  
    
