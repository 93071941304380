.completePartnerScreen {
    max-width: 615px;
    margin: 60px auto 112px;
    padding: 20px;
    .ant-upload.ant-upload-select {
        width: 400px !important;
    }
    h1 {
        color: var(--dark, #1e1e2f);
        font-size: 48px;
        font-weight: 700;
        margin-bottom: 22px;
        text-align: center;
    }
    p {
        font-size: 24px;
        font-weight: 400;
        color: var(--Text-Secondry, #344054);
        text-align: center;
    }

    .formWrapper {
        max-width: 400px;
        margin: auto;
    }

    form {
        .logo {
            display: flex;
            margin-top: 60px;
            gap: 14px;
            span.ant-upload-wrapper {
                width: fit-content;
            }
        }
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 24px;
        .select {
            .ant-select-selector {
                background-color: transparent;
                height: 56px;
                border-radius: 12px;
                width: 400px;
                outline: none !important;
                border-color: rgba(102, 102, 102, 0.35) !important;

                &:hover {
                    border-color: rgba(102, 102, 102, 0.35) !important;
                    outline: none !important;
                }
                &:focus {
                    outline: none !important;
                    border-color: rgba(102, 102, 102, 0.35) !important;
                }
                font-family: "Space Grotesk";
                .ant-select-selection-item {
                    padding-inline-start: 6px;
                }
                .ant-select-selection-overflow {
                    // padding-inline-start: 6px;
                    max-height: 50px;
                    overflow: auto;
                }
            }
        }

        label {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 7px;

            span {
                font-size: 16px;
                font-weight: 600;
                color: #1e1e2f;
                display: flex;
                align-items: center;
                justify-content: space-between;
                svg {
                    cursor: pointer;
                }
            }
            textarea {
                height: 96px !important;
                resize: none;
            }
            textarea,
            input {
                outline: none;
                border: none;
                border-radius: 12px;
                width: 400px;
                // height: 56px;
                border: 1px solid rgba(102, 102, 102, 0.35);
                padding: 16px;
                font-size: 16px;
                font-weight: 400;
                color: #1e1e2f;
                background-color: transparent;

                &::placeholder {
                    font-size: 16px;
                    color: #999;
                    font-weight: 400;
                }
            }
            .fileInput {
                outline: none;
                border: none;
                border-radius: 12px;
                width: 400px;
                height: 56px;
                border: 1px solid rgba(102, 102, 102, 0.35);
                padding: 10px 16px;
                font-size: 16px;
                font-weight: 400;
                color: #1e1e2f;
                background-color: transparent;
                justify-content: space-between;
                display: flex;
                align-items: center;
            }
            .input {
                outline: none;
                border: none;
                border-radius: 12px;
                width: 400px;
                height: 56px;
                border: 1px solid rgba(102, 102, 102, 0.35);
                padding: 10px 16px;
                font-size: 16px;
                font-weight: 400;
                color: #1e1e2f;
                background-color: transparent;
            }
        }
        button {
            margin-top: 32px;
            outline: none;
            border: none;
            border-radius: 24px;
            background: var(--brand-colors-color, #5937cc);
            color: #fff;
            padding: 16px 173px;
            width: 401px;
            font-size: 14px;
            font-weight: 700;
            &:hover {
                background: #1e1e2f;
            }
        }
        .fileFormate {
            display: flex;
            align-self: flex-end;
            align-items: center;
            gap: 3px;
            font-size: 14px;
        }
    }
}
.uploadButton {
    font-weight: 600;
    font-size: 16px;
    color: #5937cc;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
