.employer__jobsPage {

    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    gap: 24px;
    &-box {
      display: flex;
      flex-direction: column;
      min-height: 100vh;
      padding: 35px 64px 66px 64px;
      max-width: 1240px;
      min-width: 1240px;

      @media only screen and (max-width: 1530px){
        max-width: 1150px;
        min-width: 1150px;
    
      }
      @media only screen and (max-width: 1440px){
        max-width: 1050px;
        min-width: 1050px;
    
      }
      @media only screen and (max-width: 1340px){
        max-width: 1000px;
        min-width: 1000px;
    
      }
      @media only screen and (max-width: 1290px){
        max-width: 950px;
        min-width: 950px;
    
      }
      @media only screen and (max-width: 1240px){
        max-width: 900px;
        min-width: 900px;
    
      }
      @media only screen and (max-width: 1190px){
        max-width: 800px;
        min-width: 800px;
    
      }
      @media only screen and (max-width: 1090px){
        max-width: 750px;
        min-width: 750px;
    
      }
      @media only screen and (max-width: 1040px){
        max-width: 700px;
        min-width: 700px;
    
      }
      @media only screen and (max-width: 990px){
        max-width: 650px;
        min-width: 650px;
    
      }
      @media only screen and (max-width: 940px){
        max-width: 600px;
        min-width: 600px;
    
      }
      @media only screen and (max-width: 890px){
        max-width: 500px;
        min-width: 500px;
        padding: 35px 0px 66px 20px;
    
      }
      @media only screen and (max-width: 790px){
        max-width: 470px;
        min-width: 470px;
    
      }
      @media only screen and (max-width: 760px){
        max-width: 400px;
        min-width: 400px;
    
      }
      @media only screen and (max-width: 700px){
        max-width: 100%;
        min-width: 100%;
        padding: 35px 44px 66px 44px;
    
      }
      @media only screen and (max-width: 420px){
        max-width: 100%;
        min-width: 100%;
        padding: 35px 34px 66px 34px;
      }
      @media only screen and (max-width: 400px){
        padding: 35px 20px 66px 20px;
      }
    }
    &-heading {
      display: flex;
      width: 100%;
      height: fit-content;
      transform-origin: top;
      justify-content: space-between;
      padding-bottom: 10px;
      border-bottom: 0.5px #999999 solid;
      border-color: #999;
      h1 {
        font-size: 40px;
        font-weight: 700;

        @media only screen and (max-width: 940px){
          font-size: 32px;
       
         }
        @media only screen and (max-width: 890px){
         font-size: 30px;
      
        }
        @media only screen and (max-width: 420px){
          font-size: 24px;
       
         }
         @media only screen and (max-width: 350px){
          font-size: 22px;
       
         }
      }
      button {
        outline: none;
        border: none;
        cursor: pointer;
        padding: 12px 40px;
        border-radius: 55px;
        border: 1px solid var(--Purple);
        background: var(--Purple);
        color: var(--Very-Lite-Purple, #faf9ff);
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        height: min-content;
        transition: all 0.3s;
        &:hover {
          background-color: var(--Text-Primary);
        }
        @media only screen and (max-width: 940px){
        //  font-size: 12px;
         padding: 12px 30px;
        }
        @media only screen and (max-width: 890px){
           font-size: 12px;
           padding: 12px 20px;
        }
          @media only screen and (max-width: 790px){
            font-size: 10px;
        }
      }
    }
    &-container {
      display: flex;
      flex-direction: column;
      &-title {
        font-size: 24px;
        font-weight: 700;
        color: var(--Text-Primary);
        margin-bottom: 36px;
      }
      &-analytics {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 24px;
      }
      &-score {
        display: flex;
        gap: 26px;
        margin-top: 40px;
        .score {
          display: flex;
          flex-direction: column;
          padding: 32px 26px 30px 38px;
          gap: 8px;
          border-radius: 24px;
          background: #faf9ff;
          flex: 1;
          div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: var(--Text-Secondry);
            font-size: 12px;
            font-weight: 600;
            span {
              color: var(--Purple);
              text-align: right;
              font-size: 12px;
              font-weight: 500;
              line-height: 150%; /* 18px */
              letter-spacing: -0.24px;
            }
          }
          main {
            display: flex;
            border-radius: 8px;
            background: rgba(153, 153, 153, 0.2);
            width: 100%;
            height: 8px;
            position: relative;
            span {
              position: absolute;
              top: -4px;
              left: 79%;
              display: flex;
              width: 16px;
              height: 16px;
              border: 2px solid #faf9ff;
              border-radius: 100%;
              background-color: #5937cc;
              // transform: translate(-2%, -10%);
            }
            section {
              // flex: 1;
              width: 80%;
              background-color: #5937cc;
              border-radius: 8px 0px 0px 8px;
            }
          }
        }
      }
    }
  }
  

  