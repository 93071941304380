.adminFooter {
    border-radius: 24px 24px;
    padding: 50px 96px 44px 96px;
    display: flex;
    margin-top: auto;
    flex-direction: column;

    @media only screen and (max-width: 500px){
     display: none;
  }

    &__copyright {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--grey, var(--color, #999));
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
}