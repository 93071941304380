.employerBilling {
  // &__card-container {
  //   display: flex;
  //   flex-wrap: wrap;
  //   gap: 50px; // Adjust this value as needed for the gap between cards

  //   @media only screen and (max-width: 81.25em) {
  //     // 1300px
  //     margin-left: 98px;
  //     gap: 54px; // Adjust this value as needed for the gap between cards
  //   }
  // }
  &__card {
    border-radius: 24px;
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    min-width: 294px !important;
    max-width: 294px;
    gap: 24px;
    @media only screen and (max-width: 91.805625em) {
      min-width: 247px;
      max-width: 247px;
    }
    @media only screen and (max-width: 82.01375em) {
      min-width: 217px;
      max-width: 217px;
    }
    @media only screen and (max-width: 71.875em) {
      min-width: 100%;
      max-width: 100%;
    }
    &-plan-name {
      color: #1e1e2f;
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      margin-block: 20px 8px;
    }
    &-pricing {
      color: #1e1e2f;
      text-align: center;
      font-size: 48px;
      font-weight: 500;
      letter-spacing: -0.96px;
      span {
        color: var(--Text--Primary, #1e1e2f);
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.32px;
      }
    }
    &-description {
      color: #344054;
      margin-top: 8px;
      text-align: center;
      font-family: "Space Grotesk";
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: auto;
    }
    &-button {
      color: #1e1e2f;
      font-family: "Space Grotesk";
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      padding-block: 12px;
      border-radius: 24px;
      border: 1px solid #000;
      background: rgba(30, 30, 47, 0);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      width: 100%;
      text-align: center;
      margin-top: 16px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &:hover {
        color: white;
        background-color: #1e1e2f;
      }
      &.subscribed {
        color: white;
        background-color: #1e1e2f;
        cursor: not-allowed;
      }
    }
  }
}
