.employer__dashboard {
  .pie-chart-box {
    width: 400px;
    // width: calc(100%/3);
    height: 500px;
    background-color: #faf9ff;
    border: 2px solid #faf9ff;
    border-radius: 10px;
    @media only screen and (max-width: 74.375em) {
      width: 320px;
    }
    @media only screen and (max-width: 68.125em) {
      width: 100%;
    }
  }
  .pie-chart-section {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 90.1875em) {
      gap: 20px;
    }
    @media only screen and (max-width: 87.5em) {
    flex-wrap: wrap;
    justify-content: space-around;
    }
    @media only screen and (max-width: 68.0625em) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .pie-chart-label-section {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    gap: 10px;
    /* overflow-y: scroll; */
    /* height: 140px; */
  }
  .pie-chart-headers {
    display: flex;
    padding-inline: 8px;
    justify-content: space-between;
    border-bottom: 1px solid;

    padding-bottom: 10px;
  }

  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  &-box {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 35px 64px 66px 64px;
    max-width: 1240px;
    min-width: 1240px;
    @media only screen and (max-width: 95.5em) {
      max-width: 1150px;
      min-width: 1150px;
    }
    @media only screen and (max-width: 90.2em) {
      max-width: 1050px;
      min-width: 1050px;
    }
    @media only screen and (max-width: 86.8em) {
      max-width: 1000px;
      min-width: 1000px;
    }
    @media only screen and (max-width: 83.68em) {
      max-width: 900px;
      min-width: 900px;
    }
    @media only screen and (max-width: 74.32em) {
      max-width: 800px;
      min-width: 800px;
    }
    @media only screen and (max-width: 68.062em) {
      max-width: 650px;
      min-width: 650px;
    }
    @media only screen and (max-width: 58.6875em) {
      max-width: 550px;
      min-width: 550px;
    }
    @media only screen and (max-width: 52.4375em) {
      max-width: 450px;
      min-width: 450px;
      padding: 35px 14px 66px 34px;
    }
    @media only screen and (max-width: 46.25em) {
      max-width: 400px;
      min-width: 400px;
    }
    @media only screen and (max-width: 43.6875em) {
      max-width: unset;
      min-width: 100%;
    }
    @media only screen and (max-width: 25.5625em) {
      max-width: 350px;
      min-width: 350px;
      margin-top: 20px;
      padding: 0;
    }
    @media only screen and (max-width: 23.75em) {
      max-width: 300px;
      min-width: 300px;
    }
  }
  &-heading {
    display: flex;
    width: 100%;
    height: fit-content;
    transform-origin: top;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 0.5px #999999 solid;
    border-color: #999;
    // @media only screen and (max-width: 768px) {
    //   display: block;
    // }
    h1 {
      font-size: 40px;
      font-weight: 700;
      @media only screen and (max-width: 58.6875em) {
        font-size: 32px;
      }
      @media only screen and (max-width: 46.1875em) {
        font-size: 28px;
      }
      @media only screen and (max-width: 23.75em) {
        font-size: 22px;
      }
    }
    button {
      outline: none;
      border: none;
      cursor: pointer;
      padding: 12px 40px;
      border-radius: 55px;
      border: 1px solid var(--Purple);
      background: var(--Purple);
      color: var(--Very-Lite-Purple, #faf9ff);
      text-align: center;
      font-size: 14px;
      font-weight: 700;
      height: min-content;
      transition: all 0.3s;
      @media only screen and (max-width: 58.6875em) {
        padding: 8px 30px;
      }
      @media only screen and (max-width: 46.1875em) {
        padding: 6px 26px;
      }
      &:hover {
        background-color: var(--Text-Primary);
      }
    }
  }
  &-container {
    display: flex;
    flex-direction: column;
    &-title {
      font-size: 24px;
      font-weight: 700;
      color: var(--Text-Primary);
      margin-bottom: 36px;
      @media only screen and (max-width: 23.75em) {
        font-size: 18px;
      }
    }
    &-analytics {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
      @media only screen and (max-width: 86.8em) {
        flex-wrap: wrap;
      }
      @media only screen and (max-width: 1024px) {
        flex-wrap: wrap;
        gap: 12px;
      }
      @media only screen and (max-width: 58.6875em) {
        display: block;
      }
    }
    &-score {
      display: flex;
      gap: 26px;
      margin-top: 40px;
      @media only screen and (max-width: 58.6875em) {
        display: block;
      }
      .score {
        display: flex;
        flex-direction: column;
        padding: 32px 26px 30px 38px;
        gap: 8px;
        border-radius: 24px;
        background: #faf9ff;
        flex: 1;
        @media only screen and (max-width: 58.6875em) {
          margin-bottom: 15px;
        }
        div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          span {
            &:first-of-type {
              color: var(--Text-Secondry);
              font-size: 12px;
              font-weight: 600;
              display: flex;
              gap: 4px;
              align-items: flex-end;
              svg {
                width: 12px;
                height: 12px;
                cursor: pointer;
                margin-top: -4px;
              }
            }
            &:last-of-type {
              text-align: right;
              color: var(--Purple);
              font-size: 12px;
              font-weight: 500;
              line-height: 150%; /* 18px */
              letter-spacing: -0.24px;
            }
          }
        }
        &.tribaja__score {
          main {
            span {
              background-color: #fbad84;
            }
            section {
              background-color: #fbad84;
            }
          }
        }
        main {
          display: flex;
          border-radius: 8px;
          background: rgba(153, 153, 153, 0.2);
          width: 100%;
          height: 8px;
          position: relative;
          span {
            position: absolute;
            top: -4px;
            left: 79%;
            display: flex;
            width: 16px;
            height: 16px;
            border: 2px solid #faf9ff;
            border-radius: 100%;
            background-color: #5937cc;
            // transform: translate(-2%, -10%);
          }
          section {
            // flex: 1;
            width: 80%;
            background-color: #5937cc;
            border-radius: 8px 0px 0px 8px;
          }
        }
      }
    }
  }
}

// .main__dashboard {
//   display: flex;
//   flex: 1;
//   flex-direction: column;
//   width: 100%;
//   gap: 24px;
//   //   margin-left: 300px;

//   &-recent-activities {
//     /* Rectangle 4414 */

//     // max-width: 1032px;
//     margin-top: 40px;
//     background: #faf9ff;
//     border-radius: 24px;
//     padding: 30px 30px;

//     height: fit-content;
//     max-height: 500px;
//   }

//   &-recent-activities-container {
//     margin-top: 20px;
//   }

//   &-box {
//     display: flex;
//     flex-direction: column;
//     min-height: 100vh;
//     padding: 35px 28px 66px 64px;
//     max-width: 1400px;
//     width: 1400px;
//     margin-left: 300px;
//   }
//   &-heading {
//     display: flex;
//     width: 100%;
//     height: fit-content;
//     transform-origin: top;
//     justify-content: space-between;
//     padding-bottom: 10px;
//     border-bottom: 0.5px #999999 solid;
//     border-color: #999;
//     h1 {
//       font-size: 40px;
//       font-weight: 700;
//     }
//     button {
//       outline: none;
//       border: none;
//       cursor: pointer;
//       padding: 12px 40px;
//       border-radius: 55px;
//       border: 1px solid var(--Purple);
//       background: var(--Purple);
//       color: var(--Very-Lite-Purple, #faf9ff);
//       text-align: center;
//       font-size: 14px;
//       font-weight: 700;
//       height: min-content;
//       transition: all 0.3s;
//       &:hover {
//         background-color: var(--Text-Primary);
//       }
//     }
//   }
//   &-container {
//     display: flex;
//     flex-direction: column;
//     &-title {
//       font-size: 24px;
//       font-weight: 700;
//       color: var(--Text-Primary);
//       margin-bottom: 36px;
//     }
//     &-analytics {
//       display: flex;
//       align-items: center;
//       justify-content: space-between;
//       gap: 24px;
//       &-purple {
//         circle {
//           fill: #bdafeb;
//         }
//       }
//       &-lGreen {
//         circle {
//           fill: #91c7b1;
//         }
//       }
//       &-lPeach {
//         circle {
//           fill: #fdceb5;
//         }
//       }
//     }
//   }
// }

// .iti--allow-dropdown {
//   width: 100%;
// }

// /* Styles for the modal */

// .filterSection {
//   .select {
//     .ant-select-arrow {
//       margin-right: 20px;
//     }
//     .ant-select-selector {
//       background-color: transparent;
//       height: 52px;
//       border-radius: 24px;
//       outline: none !important;
//       border-color: #999999 !important;
//       display: flex;
//       align-items: center;
//       font-size: 16px;
//       font-weight: 400;
//       padding-left: 20px;
//       .ant-select-selection-item {
//         span {
//           display: none !important;
//         }
//       }
//       &:hover {
//         border-color: rgba(102, 102, 102, 0.35) !important;
//         outline: none !important;
//       }
//       &:focus {
//         outline: none !important;
//         border-color: rgba(102, 102, 102, 0.35) !important;
//       }
//       font-family: "Space Grotesk";
//       .ant-select-selection-item {
//         padding-inline-start: 6px;
//       }
//     }
//   }
//   input {
//     outline: none;
//     border: none;
//     border-radius: 12px;
//     border: 1px solid rgba(102, 102, 102, 0.35);
//     padding: 16px;
//     font-size: 16px;
//     font-weight: 400;
//     color: #1e1e2f;
//     background-color: transparent;
//     cursor: pointer;
//     margin-left: 20px;
//   }
//   .ant-dropdown-menu-item .ant-dropdown-menu-item-only-child {
//     padding: 10px 20px;
//   }
//   .roleType__option {
//     display: flex !important;
//     flex-direction: column !important;
//     gap: 6px !important;
//     transition: all 0.1s !important;
//     padding: 12px 32px !important;

//     &.ant-select-item-option-selected {
//       background-color: #5937cc !important;
//       color: white !important;
//       .ant-select-item-option-content {
//         span {
//           color: white !important;
//         }
//       }
//     }

//     &:hover {
//       background-color: #faf9ff !important;
//       .ant-select-item-option-content {
//         color: var(--Text-Primary) !important;
//         span {
//           color: #999 !important;
//         }
//       }
//     }

//     .ant-select-item-option-content {
//       display: flex !important;
//       flex-direction: column !important;
//       gap: 2px !important;
//       font-family: "Space Grotesk" !important;
//       font-size: 16px !important;
//       font-weight: 600 !important;
//       line-height: 150% !important;
//       transition: all 0.1s !important;
//       span {
//         color: #999 !important;
//         font-family: "Space Grotesk" !important;
//         transition: all 0.1s !important;
//         font-size: 14px !important;
//         font-weight: 400 !important;
//         line-height: 150% !important;
//         white-space: normal !important;
//       }
//     }
//   }
// }

.employer-dashboard-activities .main__dashboard-recent-activities {
  @media only screen and (max-width: 52.4375em) {
    width: 100% !important ;
  }
  @media only screen and (max-width: 84.8em) {
    width: 100% !important ;
  }
}

.ant-tooltip.tooltip {
  --antd-arrow-background-color: #5937cc !important;
  font-size: 12px;
  .ant-tooltip-inner {
    background-color: #5937cc !important;
    color: #d4cbef !important;
  }
}
