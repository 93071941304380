.viewResource {
    display: flex;
    flex: 1;
    width: 100%;
    gap: 24px;
    min-height: 100vh;
    // flex-direction: column;
    .tail-spin{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    &__container {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        padding: 50px 100px;
         min-width: 1400px;
         max-width: 1400px;
         @media only screen and (max-width: 95.5em) {
            max-width: 1350px;
            min-width: 1350px;
          } 
          @media only screen and (max-width: 90.2em) {
            max-width: 1300px;
            min-width: 1300px;
          } 
          @media only screen and (max-width: 83.75em) {
            max-width: 1200px;
            min-width: 1200px;
          } 
          @media only screen and (max-width: 75.625em) {
            max-width: 1100px;
            min-width: 1100px;
          } 
          @media only screen and (max-width: 69.375em) {
            max-width: 1000px;
            min-width: 1000px;
          } 
          @media only screen and (max-width: 63.125em) {
            max-width: unset;
            min-width: 100%;
          }
          @media only screen and (max-width: 43.75em) {
            padding: 50px 50px;
          }
          @media only screen and (max-width: 31.25em) {
            padding: 50px 20px;
          }

        &-heading {
            display: flex;
            flex-direction: column;
            gap: 4px;
            h2 {
                color: #000;
                font-size: 24px;
                font-weight: 600;
                line-height: 24px;
            }
            p {
                color: #344054;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
            }
            a {
                outline: none;
                border: none;
                padding: 12px 40px;
                color: var(--Lt-grey, #f2f4f7);
                text-align: center;
                font-size: 14px;
                font-weight: 700;
                border-radius: 55px;
                width: fit-content;
                background: var(--Purple, #5937cc);
                margin-top: 20px;
                cursor: pointer;
                transition: all 0.2s ease-in;
                &:hover {
                    background-color: var(--Text-Primary);
                }
            }
        }
    }
    &__box {
        display: flex;
        margin-top: 32px;
        gap: 30px;
        // border-top: 1px solid #999;
        border-bottom: 1px solid #999;
        width: 100%;
        @media only screen and (max-width: 43.75em) {
            flex-direction: column;
          }
        &-description,
        &-diversity,
        &-type,
        &-perks,
        &-cost {
            display: flex;
            flex-direction: column;
            gap: 16px;
            color: #000;
            font-size: 16px;
            font-weight: 500;
            padding-bottom: 32px;
            border-bottom: 1px solid #999;
            p {
                color: var(--Text--Primary, #1e1e2f);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
            .tag-box {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 16px;
                .tag {
                    padding: 4px 12px;
                    border-radius: 16px;
                    background: var(--gray-100, #f2f4f7);
                    color: var(--gray-700, #344054);
                    text-align: center;
                    font-family: Space Grotesk;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px; /* 142.857% */
                }
            }
        }
        .col-1,
        .col-2 {
            gap: 32px;
            padding-top: 32px;
            padding-right: 60px;
            display: flex;
            flex-direction: column;
            border-right: 1px solid #999;
            width: 50%;
            @media only screen and (max-width: 43.75em) {
                width: 100%;
              }
            & > div.viewResource__box-type {
                border-bottom: none;
            }
        }
        .col-1{
            @media only screen and (max-width: 43.75em) {
                border-right: none;
              }
        }
        .col-2 {
            border-right: none;
            padding-right: 0;
            @media only screen and (max-width: 43.75em) {
                border-top: 1px solid #999;
              }
        }
    }
}

.resource-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex: 1;
  width: 100%;
}
