.partner__dashboard {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    gap: 24px;
    &-box {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        padding: 35px 64px 66px 64px;
        max-width: 1240px;
        min-width: 1240px;

        @media only screen and (max-width: 1530px){
            max-width: 1150px;
            min-width: 1150px;
        
          }
          @media only screen and (max-width: 1440px){
            max-width: 1050px;
            min-width: 1050px;
        
          }
          @media only screen and (max-width: 1340px){
            max-width: 1000px;
            min-width: 1000px;
        
          }
          @media only screen and (max-width: 1290px){
            max-width: 950px;
            min-width: 950px;
        
          }
          @media only screen and (max-width: 1240px){
            max-width: 900px;
            min-width: 900px;
        
          }
          @media only screen and (max-width: 1190px){
            max-width: 800px;
            min-width: 800px;
        
          }
          @media only screen and (max-width: 1090px){
            max-width: 750px;
            min-width: 750px;
        
          }
          @media only screen and (max-width: 1040px){
            max-width: 700px;
            min-width: 700px;
        
          }
          @media only screen and (max-width: 990px){
            max-width: 650px;
            min-width: 650px;
        
          }
          @media only screen and (max-width: 940px){
            max-width: 600px;
            min-width: 600px;
        
          }
          @media only screen and (max-width: 890px){
            max-width: 550px;
            min-width: 550px;
            padding: 35px 44px 66px 44px;
        
          }
          @media only screen and (max-width: 850px){
            max-width: 500px;
            min-width: 500px;
        
          }
          @media only screen and (max-width: 800px){
            max-width: 450px;
            min-width: 450px;
        
          }
          @media only screen and (max-width: 770px){
            max-width: 400px;
            min-width: 400px;
        
          }
          @media only screen and (max-width: 700px){
            max-width: 100%;
            min-width: 100%;
            padding: 35px 64px 66px 64px;
        
          }
          @media only screen and (max-width: 510px){
            max-width: 100%;
            min-width: 100%;
            padding: 35px 34px 66px 34px;
          }
          @media only screen and (max-width: 400px){
            padding: 35px 30px 66px 30px;
          }
        
          @media only screen and (max-width: 370px){
            max-width: 100%;
            min-width: 100%;
          }
    }
    &-heading {
        display: flex;
        width: 100%;
        height: fit-content;
        transform-origin: top;
        justify-content: space-between;
        padding-bottom: 10px;
        border-bottom: 0.5px #999999 solid;
        border-color: #999;
        h1 {
            font-size: 40px;
            font-weight: 700;

            @media only screen and (max-width: 1190px){
             font-size: 30px;
          
            }
            @media only screen and (max-width: 1040px){
                font-size: 26px;
             
            }
              @media only screen and (max-width: 940px){
               font-size: 22px;
            
              }
              @media only screen and (max-width: 420px){
                font-size: 16px;
              }
              @media only screen and (max-width: 400px){
                font-size: 18px;
              }
              @media only screen and (max-width: 340px){
                font-size: 16px;
              }
        }
        .button-group {
            display: flex;
            align-items: center;
            gap: 24px;

            @media only screen and (max-width: 1190px){
              gap: 15px;
           
             }
             @media only screen and (max-width: 1040px){
              gap: 10px;
           
             }
             @media only screen and (max-width: 420px){
              gap: 4px;
           
             }
            button {
                outline: none;
                border: none;
                cursor: pointer;
                padding: 12px 40px;
                border-radius: 55px;
                border: 1px solid var(--Purple);
                background: var(--Purple);
                color: var(--Very-Lite-Purple, #faf9ff);
                text-align: center;
                font-size: 14px;
                font-weight: 700;
                height: min-content;
                transition: all 0.3s;
                @media only screen and (max-width: 1190px){
                  font-size: 12px;
                  padding: 12px 30px;
               
                 }
                 @media only screen and (max-width: 1040px){
                  font-size: 10px;
                  padding: 12px 25px;
               
                 }
                 @media only screen and (max-width: 940px){
                  padding: 12px 20px;
               
                 }
                 @media only screen and (max-width: 890px){
                  padding: 12px 15px;
                  font-size: 8px;
               
                 }
                 @media only screen and (max-width: 420px){
                  padding: 10px 12px;
                  font-size: 8px;
               
                 }
                 @media only screen and (max-width: 400px){
                  padding: 10px 12px;
                  font-size: 12px;
               
                 }
                 @media only screen and (max-width: 340px){
                  padding: 10px 12px;
                  font-size: 10px;
               
                 }
                &:hover {
                    background-color: var(--Text-Primary);
                }

                &:first-of-type {
                    color: var(--Purple);
                    width: 220px;
                    background-color: transparent;

                    @media only screen and (max-width: 1190px){
                     width: fit-content;
                   
                     }
  
                    
                    &:hover {
                        background-color: var(--Purple);
                        color: var(--Very-Lite-Purple, #faf9ff);
                    }
                }
            }
            .postBtn{

              @media only screen and (max-width: 400px){
                display: none;
               }
            }
        }
    }
    &-container {
        display: flex;
        flex-direction: column;
        &-title {
            font-size: 24px;
            font-weight: 700;
            color: var(--Text-Primary);
            margin-bottom: 36px;
        }
        &-analytics {
            display: flex;
            align-items: start;
            justify-content: start;
            gap: 24px;

            @media only screen and (max-width: 1280px){
                gap: 12px;
            }
              @media only screen and (max-width: 1060px){
                gap: 30px;
                flex-wrap: wrap;
              }
        }
    }
    &-resource {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-top: 40px;
        &-heading {
            color: var(--Text--Primary, #1e1e2f);
            font-size: 24px;
            font-weight: 700;
            line-height: 100.9%; /* 24.216px */
        }
    }
}

.partner-dashboard-activities .main__dashboard-recent-activities{

  @media only screen and (max-width: 52.4375em) {
    width: 100% !important ;
  }
  @media only screen and (max-width: 83.68em) {
    width: 100% !important;
    overflow-x: scroll !important;
  } 

  
}
