.editPreferencesModal {
  padding: 60px;
  background-color: white;
  border-radius: 24px;
  position: relative;
  gap: 20px;
  width: 498px;
  height: 389px;

  @media only screen and (max-width: 500px) {
    width: 100%;
    height: 100vh;
    border-radius: unset;
  } 

  &_closeSquareLogo{
    position: absolute;
    top: 28.62px;
    right: 32px;
  }
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    //z-index: 9999;
    background-color: #000000a7;
    width: 100vw;
    min-height: 100vh;
  }

  h1 {
    text-align: start;
    font-size: 40px;
    font-weight: 700;
    margin: 10px 0 20px 0;

    @media only screen and (max-width: 400px) {
      font-size: 32px;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .select {
      .ant-select-selector {
        background-color: transparent;
        height: 56px;
        border-radius: 12px;
        outline: none !important;
        border-color: rgba(102, 102, 102, 0.35) !important;
        overflow-x: hidden;
        overflow-y: auto;

        &:hover {
          border-color: rgba(102, 102, 102, 0.35) !important;
          outline: none !important;
        }
        &:focus {
          outline: none !important;
          border-color: rgba(102, 102, 102, 0.35) !important;
        }
        font-family: "Space Grotesk";
        .ant-select-selection-item {
          padding-inline-start: 6px;
        }
        .ant-select-selection-overflow {
          min-height: 50px;
          overflow-x: hidden;
          overflow-y: auto;
        }
      }
    }
    label {
      display: flex;
      flex-direction: column;
      gap: 7px;
      span {
        font-size: 16px;
        font-weight: 400;
        color: var(--Text-Primary);
        display: flex;
        align-items: center;
        justify-content: space-between;
        svg {
          cursor: pointer;
        }

      }
      input {
        outline: none;
        border: none;
        border-radius: 12px;
        border: 1px solid rgba(102, 102, 102, 0.35);
        padding: 16px;
        font-size: 16px;
        font-weight: 400;
        color: #1e1e2f;
        background-color: transparent;
      }
      .input {
        outline: none;
        border: none;
        border-radius: 12px;
        border: 1px solid rgba(102, 102, 102, 0.35);
        padding: 16px;
        font-size: 16px;
        font-weight: 400;
        color: #1e1e2f;
        background-color: transparent;
        cursor: pointer;
      }
    }

    p {
      font-size: 13px;
      font-weight: 400;
      text-align: center;
      color: var(--Text-Primary);
      a {
        color: #5937cc;
        text-decoration: underline;
      }
    }
  }

  button{
    margin-top: 50px;
  }
}