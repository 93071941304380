@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");
:root {
  --Text-Primary: #1e1e2f;
  --Text-Secondary: #344054;
  --Lite-Peach: #fdceb5;
  --Lite-Purple: #bdafeb;
  --Very-Lite-Purple: #faf9ff;
  --Lite-Yellow: #feedcc;
  --Lite-Grey: #f2f4f7;
  --Off-White: #ededed;
  --Dark-Grey: #999999;
  --Peach: #eba26e;
  --Purple: #5937cc;
  --Dark-1: #1e1e2f;
  --Dark-2: #344054;
  --Brand-Colors-Peach: #fbad84;
  --Brand-Colors-Yellow: #feedcc;
  --Dark: #1e1e2f;
  --FFFFFF: #ffffff;
  --Grey: #9aa0a9;
  --Neutral-600: #6f6c90;
  --Purple: #5937cc;
  --Solid-Primary-20: #d0d9fa;
}

$bp-largest: 75em; //1200px
$bp-large: 68.5em; //1100px
$bp-medium: 56.25em; //900px
$bp-small: 780px; //600px
$bp-smallest: 480px;

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  font-family: "Space Grotesk", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: var(--Text-Primary);
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

button {
  cursor: pointer;
  outline: none;
  border: none;
}

a {
  text-decoration: none;
}

.app {
  display: flex;
  width: 100%;
  flex: 1;
  min-height: 100vh;

  @media only screen and (max-width: 700px) {
    justify-content: center;
  } 

  .tail-spin {
    width: 100%;
    justify-content: center;
  }
}

.profile-tail-spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// Components

.link-button {
  padding: 8px 16px;
  border-radius: 28px;
  background-color: var(--Text-Primary);
  color: var(--Off-White);
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  border: 1px solid var(--Text-Primary);

  @media only screen and (max-width: 350px){
    font-size: 12px;
  }
  &:hover {
    background-color: transparent;
    color: var(--Text-Primary);
  }
  &.inverted {
    background-color: transparent;
    color: var(--Text-Primary);
    &:hover {
      background-color: var(--Text-Primary);
      color: var(--Off-White);
    }
  }
}

.buttonWithArrow {
  border-radius: 24px;
  background: var(--Text-Primary);
  display: flex;
  height: 48px;
  padding: 0 12px 0 16px;
  align-items: center;
  justify-content: space-between;
  width: 202px;
  line-height: 1;
  color: white;
  font-size: 14.5px;
  font-weight: 500;
  line-height: 140%;
  margin-top: 24px;
}

.roleType__option {
  display: flex !important;
  flex-direction: column !important;
  gap: 6px !important;
  transition: all 0.1s !important;
  padding: 12px 32px !important;

  &.ant-select-item-option-selected {
    margin-bottom: 2px;
    background-color: #5937cc !important;
    color: white !important;
    .ant-select-item-option-content {
      span {
        color: white !important;
      }
    }
  }

  // &:hover {
  //   background-color: #faf9ff !important;
  //   .ant-select-item-option-content {
  //     color: var(--Text-Primary) !important;
  //     span {
  //       color: #999 !important;
  //     }
  //   }
  // }

  .ant-select-item-option-content {
    display: flex !important;
    flex-direction: column !important;
    gap: 2px !important;
    font-family: "Space Grotesk" !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 150% !important;
    transition: all 0.1s !important;
    span {
      color: #999 !important;
      font-family: "Space Grotesk" !important;
      transition: all 0.1s !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 150% !important;
      white-space: normal !important;
    }

    ~ span .anticon svg {
      display: none !important;
    }
  }
}

#avatar {
  width: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fbad84;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
}

.errorMessage {
  color: #ee1d52;
  font-size: 14px;
  font-weight: 400;
  margin-top: -8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #5937cc;
  border: 1px solid #eee;
}


.slick-slide > div:first-child {
  display: flex;
  justify-content: center;
  margin: 20px;
}

#pdf-controls{
  z-index: 9999;
  width: 575px;
}

.react-pdf__Document{
  width: max-content !important;

}