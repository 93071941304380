.sidebar {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 320px;
  border-radius: 0px 24px 24px 0;
  background: #faf9ff;
  padding-block: 48px 52px;
  min-height: 100vh;
  // max-height: 100vh;
  overflow: scroll;
  position: static;
  transform-origin: left;
    min-width: 290px;

  @media screen and (max-width: 700px) {
    .sidebar__profile,
    .sidebar__list {
      display: none;
    }
  }

  &__profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-block: 52px 100px;
    gap: 8px;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 83px;
      height: 83px;
      background-color: #fbad84;
      border-radius: 50%;
      color: #fff;
      font-size: 32px;
      font-weight: 700;
      text-transform: uppercase;
    }
    p {
      font-size: 16px;
      font-weight: 700;
      color: var(--Text-Primary);
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    width: 100%;
    a {
      display: flex;
      align-items: center;
      padding: 24px 16px 24px 83px;
      gap: 16px;
      font-size: 14px;
      font-weight: 700;
      color: var(--Text-Primary);
      transition: all 0.2s;
      &.active {
        background-color: #d4cbef;
        color: #5937cc;
        svg {
          path {
            stroke: #5937cc;
          }
        }
      }
      // &:nth-of-type(2){
      //   &.active{
      //     svg{
      //       path{
      //         stroke: none !important;
      //         fill: #fbad84 !important;
      //       }
      //     }
      //   }
      // }
    }
  }
  &__button {
    margin-left: 32px;
    margin-top: 16px;
    outline: none;
    border: none;
    background-color: transparent;
    border-radius: 24px;
    cursor: pointer;
    background: var(--Text-Primary);
    padding: 16px 52px;
    color: #faf9ff;
    font-size: 18px;
    font-weight: 400;
    line-height: 100.9%;
    text-align: center;
    display: flex;
  }

  .premium-card-div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    cursor: pointer;
  }
  .premium-card {
    // margin:auto;
    // width: 50%;
    transform: scale(0.9);
  }

  .disabled-link {
    display: flex;
    align-items: center;
    padding: 24px 16px 24px 83px;
    gap: 16px;
    font-size: 14px;
    font-weight: 600;
    /* color: #dcdcdc; */
    transition: all 0.2s;
    cursor: pointer;

  }

  .career-guide {
    background: none;
    width: auto;
    margin: 0;
  }
  .career-guide.active {
    svg {
      path {
        stroke: none !important;
        fill: #5937cc !important;
      }
    }
  }
}

// for mobile navigation
.bottom-nav {
  display: none;

  @media screen and (max-width: 700px) {
    display: flex;
    justify-content: space-around;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #faf9ff;
    color: var(--Text-Primary);
    padding: 10px 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    z-index: 9999;
    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      font-weight: 700;
      color: var(--Text-Primary);
      transition: all 0.2s;
      &.active {
        background-color: #d4cbef;
        color: #5937cc;
        svg {
          path {
            stroke: #5937cc;
          }
        }
      }

      @media screen and (max-width: 420px) {
        font-size: 9px;
      }
      @media screen and (max-width: 370px) {
        font-size: 7px;
      }
      @media screen and (max-width: 340px) {
        font-size: 6px;
      }
    }

    .disabled-link {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // padding: 24px 16px 24px 83px;
      font-size: 10px;
      font-weight: 600;
      color: #dcdcdc;
      transition: all 0.2s;
      // cursor: pointer;

      @media screen and (max-width: 420px) {
        font-size: 8px;
      }
      @media screen and (max-width: 370px) {
        font-size: 7px;
      }
      @media screen and (max-width: 340px) {
        font-size: 5px;
      }
      svg {
        path {
          opacity: 0.1;
        }
      }
    }

    .career-guide-1.active {
      svg {
        path {
          stroke: none !important;
          fill: #5937cc !important;
        }
      }
    }
  }
}
