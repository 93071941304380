.adminNavbar {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 96px;
    height: 78px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    margin-bottom: auto;

}
  