.career-guide{
  width: 446px;
  height: auto;
  background: #edeef7;
  border-radius: 0 16px 16px 16px;
  padding: 15px 0 15px 50px;
  margin: 15px 0 15px 0;

  @media only screen and (max-width: 1070px){
    width: 300px;
    padding: 15px;

  }
  @media only screen and (max-width: 870px){
    width: 230px;

  }
  @media only screen and (max-width: 760px){
    width: 170px;

  }
  @media only screen and (max-width: 700px){
    width: 100%;
  }

  h6{
    padding: 10px 0 10px 0;
  }

  li{
    font-weight: 400;
    font-size: 12px;
    padding:0px 0 5px 10px;

    @media only screen and (max-width: 1070px){
     font-size: 10px;
  
    }
  }
}