.notFoundContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
    flex-direction: column;
    gap: 48px;
    p {
        color: #1e1e2f;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
    button {
        outline: none;
        background-color: transparent;
        border-radius: 55px;
        background: var(--Purple, #5937cc);
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--Lt-grey, #f2f4f7);
        text-align: center;
        font-family: "Space Grotesk", sans-serif;
        font-size: 14px;
        font-weight: 700;
        // width: 172px;
        padding: 12px 52px;
        cursor: pointer;
    }
}
