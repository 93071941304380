.reviewModal {
    padding: 60px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: white;
    border-radius: 24px;
    position: relative;
    gap: 20px;
    width: 498px;
    height: 648px;

    @media only screen and (max-width: 500px) {
      width: 100%;
      height: 100vh;
      border-radius: unset;
      padding: 40px;
    } 
  
    &_closeSquareLogo{
      position: absolute;
      top: 28.62px;
      right: 32px;
    }
    &__container {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 9999;
      background-color: #000000a7;
      width: 100vw;
      min-height: 100vh;
    }
  
    &__error-case{
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 10px;
      span{
        font-size: 16px;
        color: red;
      }
    }
  
    h1 {
      text-align: start;
      font-size: 40px;
      font-weight: 700;

      @media only screen and (max-width: 500px) {
        font-size: 32px;
      } 
      @media only screen and (max-width: 400px) {
        font-size: 28px;
      }
      @media only screen and (max-width: 350px) {
        font-size: 22px;
      }  
    }
  
    p{
        color: #999999;
        font-size: 14px;
        font-weight: 400;
    }

    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
      gap: 20px;

      @media only screen and (max-width: 500px) {
        width: 100%;
      }
      label {
        display: flex;
        flex-direction: column ;
        align-self: start;
      }
      textarea {
        resize: none;
        outline: none;
        border: 1px solid rgba(102, 102, 102, 0.35);
        color: #000;
        background-color: transparent;
        width: 400px;
        padding: 16px;
        border-radius: 12px;
        font-size: 16px;
        font-weight: 400;
      
        @media only screen and (max-width: 500px) {
          width: 100%;
        }
      }
  
      div{
        display: flex;
        align-self: flex-start;
        margin-bottom: 20px;
        gap: 12px;
  
      }
    }
  }